import React, {
  forwardRef, useCallback, useImperativeHandle, useEffect, useRef, useState,
} from 'react';
import { ClockIcon, XIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import ItemBadge from './ItemBadge';
import AutoComplete from './AutoComplete';
import { useArray, useOptionMenu } from '../../hooks';
import HistoryAPI from '../../services/HistoryAPI';

const SearchBar = forwardRef(({ onSearch }, ref) => {
  const [items, {
    setItems, push, pop, removeItemByIndex, removeAll,
  }] = useArray([]);

  const [active, setActive] = useState(false);
  const autoCompleteRef = useRef(null);
  const focusInput = () => autoCompleteRef.current.focusInput();

  useEffect(() => {
    if (active) focusInput();
  }, [active]);

  const addHistoryToSearchbar = (keywords) => {
    setItems(keywords.map((name) => ({ name, type: 'valid' })));
  };

  const {
    options: history,
    setOptions: setHistory,
    showMenu: showHistory,
    openMenu: openHistoryMenu,
    closeMenu: closeHistoryMenu,
    activeOptionIndex: activeHistoryIndex,
    setActiveOptionIndex: setActiveHistoryIndex,
    removeOptionByIndex: removeHistoryByIndex,
    handleArrowKeyDown,
  } = useOptionMenu({
    initialOptionIndex: -1,
    handleActiveOptionChange(newActiveOptionIndex) {
      if (newActiveOptionIndex === -1) setItems([]);
      else addHistoryToSearchbar(history[newActiveOptionIndex].keywords);
    },
  });

  const getHistory = useCallback(async () => {
    HistoryAPI.getUserHistory()
      .then((data) => setHistory(data.reverse()))
      .catch(() => setHistory([]));
  }, [setHistory]);

  useEffect(() => {
    getHistory();
    return () => setHistory([]);
  }, [getHistory, setHistory]);

  const addNewItem = (item) => {
    if (!active) setActive(true);
    push(item);
  };

  const deleteItem = (index, activateButton) => {
    removeItemByIndex(index);
    setActive(activateButton);
  };

  const removeItem = (item) => {
    const index = items.findIndex(({ name }) => name === item.id);
    deleteItem(index, false);
  };

  const handleClickSearchbar = () => {
    if (!active) setActive(true);
    else focusInput();

    if (!items.length) openHistoryMenu();
  };

  const onSearchBtnClick = (e) => {
    if (e) e.stopPropagation();
    if (showHistory) closeHistoryMenu();
    const validItems = items.filter(({ type }) => type === 'valid');
    const keywords = [...new Set(validItems.map(({ name }) => name))];
    setItems(keywords.map((name) => ({ name, type: 'valid' })));
    onSearch(keywords);
    if (keywords.length) {
      HistoryAPI.addToHistory(keywords).then(() => getHistory());
    }
    setActive(false);
  };

  const handleComponentBlur = () => {
    if (!items.length) setActive(false);
    if (showHistory) {
      closeHistoryMenu();
      focusInput();
    }
  };

  const handleInputChange = () => {
    if (showHistory) closeHistoryMenu();
  };

  const handleDeleteHistory = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    removeHistoryByIndex(index);
    const { timestamp } = history[index];
    HistoryAPI.deleteHistoryByTimestamp(timestamp);
  };

  const performSearch = () => {
    onSearchBtnClick(null);
  };

  useImperativeHandle(ref, () => ({
    addNewItem, performSearch, removeItem,
  }));

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowUp':
        if (showHistory) handleArrowKeyDown(e.key);
        break;
      case 'Backspace':
        if (items.length) pop();
        break;
      case 'Enter':
        if (items.length) performSearch();
        break;
      default:
        break;
    }
  };

  const actIfMouseMoving = (act) => {
    let isMouseMoving = false;
    document.onmousemove = () => { isMouseMoving = true; };
    setTimeout(() => {
      document.onmousemove = null;
      if (isMouseMoving) act();
    }, 20);
  };

  return (
    <div className="relative max-w-full" onBlur={handleComponentBlur}>
      <div
        className={classNames(active ? 'ring-1 ring-blue-600 border-blue-600' : '',
          'group w-full flex flex-row flex-wrap items-center justify-between bg-white border border-gray-300 rounded-md px-3 py-2 shadow-sm')}
        aria-hidden="true"
        onClick={handleClickSearchbar}
      >
        <div className="flex flex-row flex-wrap items-center max-w-full">
          {items.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="p-0.5 max-w-full" key={index + item.name}>
              <ItemBadge
                text={item.name}
                type={item.type}
                onDelete={() => { deleteItem(index, true); }}
              />
            </div>
          ))}
          {(active || !items.length)
            && (
              <AutoComplete
                ref={autoCompleteRef}
                onInputChange={handleInputChange}
                onInputComplete={addNewItem}
                onKeyDown={handleKeyDown}
              />
            )}
        </div>
        <div className="flex-grow flex flex-row justify-end items-center print:hidden">
          {(items.length > 0)
            && (
              <button
                type="button"
                className={classNames(active ? 'visible' : 'invisible',
                  'inline-flex items-center mr-2 p-1 text-sm font-medium rounded-full text-blue-600 group-hover:visible hover:bg-blue-100 hover:text-blue-800')}
                onClick={removeAll}
              >
                <XIcon className="h-4 w-4" aria-hidden="true" />
              </button>
            )}
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 disabled:bg-gray-500 disabled:opacity-50 disabled:cursor-default"
            onClick={onSearchBtnClick}
            disabled={!active && items.length}
          >
            Search
          </button>
        </div>
      </div>
      {(showHistory && history.length > 0)
        && (
          <ul
            className="absolute inset-x-0 mt-2 rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            style={{ zIndex: 100000 }}
            onMouseLeave={() => actIfMouseMoving(() => setActiveHistoryIndex(-1))}
          >
            {history.map(({ keywords, timestamp }, index) => (
              <li
                key={timestamp}
                aria-hidden="true"
                className={classNames(activeHistoryIndex === index ? 'bg-gray-200 text-gray-900' : 'text-gray-700',
                  'group px-2 py-1.5 flex justify-between gap-1 text-sm cursor-default text-gray-900')}
                onMouseEnter={() => actIfMouseMoving(() => setActiveHistoryIndex(index))}
                onMouseDown={() => addHistoryToSearchbar(keywords, true)}
              >
                <div className="inline-block align-middle truncate">
                  <ClockIcon className="inline-block mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                  {keywords.map((keyword, keywordIndex) => (
                    `${keyword}${(keywordIndex < (keywords.length - 1) && keywords.length > 1) ? ', ' : ''}`
                  ))}
                </div>
                <button
                  type="button"
                  className={classNames(activeHistoryIndex === index ? 'visible' : 'invisible',
                    'inline-flex items-center p-1 text-sm font-medium rounded-full text-gray-600 hover:bg-gray-300 hover:text-gray-800')}
                  onMouseDown={(e) => handleDeleteHistory(e, index)}
                >
                  <XIcon className="h-4 w-4" aria-hidden="true" />
                </button>
              </li>
            ))}
          </ul>
        )}
    </div>
  );
});

export default SearchBar;
