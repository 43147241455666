import React from 'react';
import { useFormik } from 'formik';
import Alert from '../Alert';

export default function EditKeywordForm({
  keyword, message, clearMessage, ...props
}) {
  const {
    dirty, isSubmitting, values, setSubmitting, ...formik
  } = useFormik({
    enableReinitialize: true,
    initialValues: { ...keyword },
    onSubmit: () => {
      props.onSave(values, { setSubmitting });
    },
  });

  const onDelete = () => {
    setSubmitting(true);
    props.onDelete({ setSubmitting });
  };

  const handleChange = (e) => {
    formik.handleChange(e);
    if (message) clearMessage();
  };

  return (
    <form className="space-y-8" onSubmit={formik.handleSubmit}>
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-5">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Name
            <div className="mt-1">
              <input
                type="text"
                name="name"
                id="name"
                value={values.name || ''}
                readOnly={keyword.id}
                autoComplete="off"
                required
                onChange={(e) => {
                  e.target.value = e.target.value.toLowerCase();
                  handleChange(e);
                }}
                onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault(); }}
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </label>
        </div>
      </div>
      {
        message.type && (
          <Alert type={message.type} text={message.text} />
        )
      }
      <div className="pt-5 border-t">
        {keyword.id
          && (
            <button
              type="button"
              disabled={isSubmitting}
              className="bg-white py-2 px-4 border border-red-400 rounded-md shadow-sm text-sm font-medium text-red-600 hover:text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              onClick={onDelete}
            >
              Delete
            </button>
          )}
        {!keyword.id
          && (
            <div className="float-right">
              <button
                type="submit"
                disabled={isSubmitting || !dirty}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-600 disabled:opacity-50 disabled:cursor-default"
              >
                Save
              </button>
            </div>
          )}
      </div>
    </form>
  );
}
