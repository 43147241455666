import React from 'react';
import { XCircleIcon } from '@heroicons/react/outline';
import Alert from '../Alert';

export default function ChangePasswordForm({ user, onChange, message }) {
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [isValid, setIsValid] = React.useState(true);

  const onChangeCurrentPassword = (event) => {
    setOldPassword(event.target.value);
  };
  const onChangeNewPassword = (event) => {
    setNewPassword(event.target.value);
  };
  const onChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setIsValid(false);
    } else {
      setIsValid(true);
      onChange({ token: user.token, oldPassword, newPassword });
    }
  };
  return (
    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <form className="space-y-6" onSubmit={onSubmit}>
        <p className="mt-1 text-center text-lg font-bold text-blue-600">Change Password</p>
        <div>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="Current Password" className="block text-sm font-medium text-gray-700">
            Current Password
          </label>
          <div className="mt-1">
            <input
              type="password"
              value={oldPassword}
              onChange={onChangeCurrentPassword}
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
        </div>

        <div>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="New Password" className="block text-sm font-medium text-gray-700">
            New Password
          </label>
          <div className="mt-1">
            <input
              type="password"
              value={newPassword}
              onChange={onChangeNewPassword}
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
        </div>

        <div>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="Confirm Password" className="block text-sm font-medium text-gray-700">
            Confirm Password
          </label>
          <div className="mt-1">
            <input
              type="password"
              value={confirmPassword}
              onChange={onChangeConfirmPassword}
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          {isValid ? '' : (
            <div className="rounded-md bg-red-50 p-4 mt-3">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Your new password and confirmation password do not match</h3>
                </div>
              </div>
            </div>
          )}
          {message && <Alert text={message} type="Error" />}
        </div>

        <div>
          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Change Password
          </button>
        </div>
      </form>
    </div>
  );
}
