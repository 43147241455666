/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';

const roles = [
  { label: 'Admin', value: true },
  { label: 'Member', value: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function RoleSelect(props) {
  const { value, onChange } = props;
  return (
    <Listbox value={value} onChange={(newValue) => { if (newValue !== value) onChange(newValue); }}>
      {({ open }) => (
        <div className="relative">
          <Listbox.Button>
            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute right-0 z-10 w-42 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              {roles.map((role) => (
                <Listbox.Option
                  key={role.value}
                  className={({ active }) => classNames(
                    active ? 'text-white bg-blue-600' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-8 pr-4',
                  )}
                  value={role.value}
                >
                  {({ selected, active }) => (
                    <>
                      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                        {role.label}
                      </span>
                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-blue-600',
                            'absolute inset-y-0 left-0 flex items-center pl-1.5',
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
