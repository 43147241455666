import HttpService from './HttpService';

const API_PATH = '/documents';

const getAllDocs = ({ pageNumber, pageSize }) => HttpService
  .get(`${API_PATH}/getAllDocs`, { pageNumber, pageSize })
  .then(({ documents, totalCount }) => Promise.resolve({ rows: documents, totalCount }));

const findDocumentsByInput = (input) => HttpService
  .get(`${API_PATH}/findDocumentsByInput`, { input });

const deleteDocument = (id) => HttpService
  .post(`${API_PATH}/deleteDocument`, { id });

const getDocumentById = (id) => HttpService
  .get(`${API_PATH}/getDocumentById`, { id });

const getKeywordConnections = (id) => HttpService
  .get(`${API_PATH}/getKeywordConnections`, { id });

const createDocument = ({
  date, title, text, url, language, sitename,
}) => HttpService.post(`${API_PATH}/createDocument`, {
  date, title, text, url, language, sitename,
});

const editDocument = ({
  id, date, language, sitename, text, title, url,
}) => HttpService.post(`${API_PATH}/editDocument`, {
  id, date, language, sitename, text, title, url,
});

const editKeywordConnections = ({ id, removedConnections, newConnections }) => HttpService
  .post(`${API_PATH}/editKeywordConnections`, { id, removedConnections, newConnections });

export default {
  getAllDocs,
  findDocumentsByInput,
  deleteDocument,
  getDocumentById,
  getKeywordConnections,
  createDocument,
  editDocument,
  editKeywordConnections,
};
