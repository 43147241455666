import React, { useCallback, useEffect, useState } from 'react';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import DocumentTemplate from './DocumentTemplate';

export default function DocumentList({ documents }) {
  const [selectedFilter, setSelectedFilter] = useState('None');

  const filterIsNone = useCallback(() => selectedFilter === 'None', [selectedFilter]);
  const filterIsNew = useCallback(() => selectedFilter === 'New', [selectedFilter]);
  const filterIsColor = useCallback(() => selectedFilter.startsWith('rgb') || selectedFilter.startsWith('#'), [selectedFilter]);

  useEffect(() => {
    if (filterIsColor() && !documents.colorClusters.get(selectedFilter)) {
      setSelectedFilter('None');
    }
  }, [documents, filterIsColor, selectedFilter]);
  return (
    <div>
      <div className="sticky top-0 py-3 text-sm font-medium bg-gray-50 text-gray-600 uppercase border-gray-200 flex justify-between items-center flex-wrap gap-y-1 gap-x-8">
        <p className="pl-6">Top Related Documents</p>
        {documents.colorClusters.size > 0 && (
          <div className="flex-grow flex justify-end pr-2">
            <Menu as="div" className="relative inline-block">
              <div className="flex flex-row items-center">
                Filter:
                <Menu.Button className="ml-1 items-center inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
                  {
                    filterIsColor() ? <div style={{ width: '30px', height: '10px', background: selectedFilter }} /> : <span>{selectedFilter}</span>
                  }
                  <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-15 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        onClick={() => { setSelectedFilter('None'); }}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm content-center',
                        )}
                      >
                        None
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        onClick={() => { setSelectedFilter('New'); }}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm content-center',
                        )}
                      >
                        <span className="items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">New</span>
                      </button>
                    )}
                  </Menu.Item>
                  {
                    Array.from(documents.colorClusters.keys()).map((c) => (
                      <Menu.Item key={c}>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={() => {
                              setSelectedFilter(c);
                            }}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm content-center',
                            )}
                          >
                            <div style={{ width: '40px', height: '10px', background: c }} />
                          </button>
                        )}
                      </Menu.Item>
                    ))
                  }
                </div>
              </Menu.Items>
            </Menu>
          </div>
        )}
      </div>
      <div className="w-full border-t" />
      <ul className="divide-y divide-gray-200">
        {documents.entries
          .filter((d) => (filterIsColor() && d.relatedKeywords
            .some((rkw) => ((documents.colorClusters.get(selectedFilter) || [])
              .includes(rkw)))) || (filterIsNew() && d.isNew) || filterIsNone()).map((document) => (
                <li key={document.source}>
                  <DocumentTemplate
                    document={document}
                    searchPhrases={documents.searchPhrases}
                  />
                </li>
          ))}
      </ul>
    </div>
  );
}
