import React, { useEffect } from 'react';
import ForceGraph2D from 'react-force-graph-2d';
import { forceManyBody } from 'd3-force';

const { useRef } = React;

const force = forceManyBody();
force.strength(-175);

function ResultGraph2D({
  onNodeClick, onNodeRightClick, otherClick,
  graphData, containerWidth, containerHeight,
  resetCamera,
  setResetCamera,
}) {
  const fgRef = useRef();
  useEffect(() => {
    if (fgRef.current) {
      fgRef.current.d3Force('link').id((i) => i.id).strength((d) => d.value / 8);
      fgRef.current.d3Force('charge', force);
    }
  }, [graphData]);
  useEffect(() => {
    if (resetCamera && fgRef.current) {
      fgRef.current.zoomToFit(400, 30);
      setResetCamera(false);
    }
  }, [resetCamera, setResetCamera]);
  useEffect(() => {
    if (fgRef.current) {
      fgRef.current.zoom(4, 0);
    }
  }, []);
  return (
    <ForceGraph2D
      ref={fgRef}
      graphData={graphData}
      nodeCanvasObjectMode={() => 'after'}
      nodeCanvasObject={(node, ctx, globalScale) => {
        const label = node.id;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = 'black';
        if (node.isSearchTerm) {
          ctx.font = `bold ${20 / globalScale}px Sans-Serif`;
        } else {
          ctx.font = `${20 / globalScale}px Sans-Serif`;
        }
        ctx.fillText(label, node.x, node.y);
      }}
      width={containerWidth}
      height={containerHeight}
      linkOpacity={0.8}
      linkWidth={1.05}
      linkColor="#000000" // not working
      nodeColor={(node) => node.color}
      backgroundColor="#f8f8f8"
      onNodeClick={(node) => onNodeClick(node)}
      onNodeRightClick={(node, event) => {
        onNodeRightClick({
          x: event.pageX,
          y: event.pageY,
          node,
        });
      }}
      onBackgroundClick={() => {
        otherClick();
      }}
      onLinkClick={() => {
        otherClick();
      }}
      onZoom={() => {
        otherClick();
      }}
      onNodeDrag={() => {
        otherClick();
      }}
    />
  );
}

export default ResultGraph2D;
