import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Tabs({ tabs, currentTab, onTabChange }) {
  return (
    <div>
      <div className="sm:hidden">
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          defaultValue={currentTab}
          onChange={(e) => { onTabChange(e.target.value); }}
        >
          {tabs.map((tab) => (
            <option key={tab.name} disabled={tab.disabled}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <div className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                className={classNames(
                  // eslint-disable-next-line no-nested-ternary
                  tab.name === currentTab
                    ? 'border-blue-500 text-blue-600'
                    : tab.disabled === true
                      ? 'border-transparent text-gray-300 cursor-default'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-normal',
                )}
                type="button"
                disabled={tab.disabled}
                onClick={() => onTabChange(tab.name)}
              >
                {tab.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
