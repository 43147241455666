import React from 'react';
import {
  Route, Routes,
} from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Search from './Search';
import UserProfile from './UserProfile';
import UserSettings from './UserSettings';
import UserManagement from './admin/UserManagement';
import DocumentManagement from './admin/DocumentManagement';
import KeywordManagement from './admin/KeywordManagement';
import EditDocument from './admin/EditDocument';
import EditKeyword from './admin/EditKeyword';

export default function User({ user }) {
  return (
    <div className="bg-gray-50 lg:flex lg:divide-x">
      <div className="hidden lg:block lg:flex-shrink-0 lg:h-full">
        <div className="flex" style={{ minHeight: 'calc(100vh - 48px)' }}>
          <div className="px-3 pt-4 xl:w-48 2xl:w-60 bg-gray-50">
            <div className="sticky top-4 flex flex-col inset-y-0 space-y-4">
              {user && <Sidebar user={user} />}
            </div>
          </div>
        </div>
      </div>
      <div className="flex-grow px-3 py-4 md:p-6 pb-0">
        <Routes>
          <Route path="/" element={<Search />} />
          <Route path="/user/profile" element={<UserProfile />} />
          <Route path="/user/settings" element={<UserSettings />} />
          <Route path="/admin/user-management" element={<UserManagement />} />
          <Route path="/admin/document-management" element={<DocumentManagement />} />
          <Route path="/admin/document-management/:id" element={<EditDocument />} />
          <Route path="/admin/keyword-management" element={<KeywordManagement />} />
          <Route path="/admin/keyword-management/:id" element={<EditKeyword />} />
        </Routes>
      </div>
    </div>
  );
}
