import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Formik, Form, FieldArray } from 'formik';
import { PlusSmIcon } from '@heroicons/react/outline';
import KeywordConnection from './KeywordConnection';
import Alert from '../Alert';

const newConnection = () => ({ id: uuid(), node2: '', score: '' });

export default function KeywordConnectionGroup({ initialConnections, message, ...props }) {
  const [connections, setConnections] = useState([]);

  useEffect(() => {
    initialConnections.forEach((connection) => {
      Object.assign(connection, { id: uuid() });
    });
    if (initialConnections.length < 5) {
      const newConnections = [];
      for (let i = initialConnections.length; i < 5; i += 1) {
        newConnections.push(newConnection());
      }
      setConnections([...initialConnections, ...newConnections]);
    } else {
      setConnections(initialConnections);
    }
  }, [initialConnections, setConnections]);

  const isContained = (connection, array) => {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i] === connection) return true;
    }
    return false;
  };

  const onSave = (values, { setSubmitting }) => {
    const removedConnections = [];
    const newConnections = [];
    const currentConnections = values.connections.filter((connection) => connection.node2 !== '');
    currentConnections.forEach((connection) => {
      if (!isContained(connection, initialConnections)) {
        const { node2, score } = connection;
        newConnections.push({ node2, score: Number(score) });
      }
    });
    initialConnections.forEach((connection) => {
      if (!isContained(connection, currentConnections)) {
        removedConnections.push(connection.node2);
      }
    });
    props.onSave(removedConnections, newConnections, { setSubmitting });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ connections }}
      validate={(values) => {
        let hasError = false;
        const errors = { connections: [] };
        values.connections.forEach((connection) => {
          const error = {};
          const score = Number(connection.score);
          if (Number.isNaN(score) || score >= 1) {
            error.score = 'Please input a number less than 1';
            hasError = true;
          }
          errors.connections.push(error);
        });
        return !hasError ? {} : errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSave(values, { setSubmitting });
      }}
    >
      {({
        values, dirty, isSubmitting, isValid, ...formik
      }) => (
        <Form>
          <div className="space-y-8">
            <FieldArray name="connections">
              {({ remove, push }) => (
                <div className="mt-6 grid grid-cols-12 gap-y-8 gap-x-6">
                  {values.connections.map((connection, index) => (
                    <KeywordConnection
                      key={connection.id}
                      index={index}
                      connection={connection}
                      formik={formik}
                      onDelete={() => { remove(index); }}
                    />
                  ))}
                  <div className="col-start-2 col-span-8">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center items-center py-2  border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-600 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={() => push(newConnection())}
                    >
                      <PlusSmIcon className="-ml-0.5 mr-2 h-4 w-4 text-gray-500" aria-hidden="true" />
                      <span className="tracking-wide">Add New Connection</span>
                    </button>
                  </div>
                </div>
              )}
            </FieldArray>
            {
              !dirty && message.type && (
                <Alert type={message.type} text={message.text} />
              )
            }
            <div className="pt-5 border-t">
              <div className="float-right">
                <button
                  type="submit"
                  disabled={isSubmitting || !dirty || !isValid}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-600 disabled:opacity-50 disabled:cursor-default"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
