import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/solid';
import Pagination from '../../components/table/Pagination';
import Loading from '../../components/Loading';
import Notification from '../../components/Notification';
import KeywordSearchBar from '../../components/admin/KeywordSearchBar';
import KeywordsAPI from '../../services/KeywordsAPI';
import { useManagementTable } from '../../hooks';

const columns = ['Keyword Name'];
const pageSizeOptions = [10, 25, 50];

export default function KeywordManagement() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    pending,
    pagination,
    count,
    rows,
    errorMessage,
    notification,
    setPagination,
    handleSearch,
    handleClearSearchInput,
    closeNotification,
  } = useManagementTable({
    pageSizeOptions,
    getRowsfn: KeywordsAPI.getAllKeywords,
    geRowsbyInputfn: KeywordsAPI.findKeywordByName,
  });

  return (
    <>
      <div className="flex flex-col gap-5">
        <div className="flex justify-between gap-5">
          <KeywordSearchBar
            placeholder="Search keyword"
            onSearch={handleSearch}
            onClear={handleClearSearchInput}
          />
          <button
            type="button"
            className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm leading-4 font-medium rounded-md text-blue-700 bg-transparent hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={() => navigate(`${pathname}/create`)}
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Create
          </button>
        </div>
        <div className="relative flex flex-col overflow-hidden rounded-lg shadow bg-white">
          {/* <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"> */}
          <div className="border-b border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  {columns.map((name) => (
                    <th
                      key={name}
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {name}
                    </th>
                  ))}
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {rows.map((keyword) => (
                  <tr key={keyword.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {keyword.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <button
                        type="button"
                        className="text-blue-600 hover:text-blue-900"
                        onClick={() => { navigate(`${pathname}/${keyword.id}`, { state: { pagination } }); }}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
                {(errorMessage.length > 0)
                  && (
                    <tr className="text-center align-middle h-48">
                      <td colSpan="6" className="text-gray-500 text-center align-middle ">{errorMessage}</td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
          <Pagination
            count={count}
            pagination={pagination}
            pageSizeOptions={pageSizeOptions}
            onPaginationChange={setPagination}
          />
          {pending && <Loading />}
        </div>
      </div>
      {notification.show && (
        <Notification
          show={notification.show}
          type={notification.type}
          text={notification.text}
          title={notification.title}
          onClose={closeNotification}
        />
      )}
    </>
  );
}
