import React from 'react';

function getHostName(url) {
  try {
    const { hostname } = new URL(url);
    const str = hostname.split('.');
    if (str.length && str[0] === 'www') {
      return hostname.substring(4);
    }
    return hostname;
  } catch {
    return '';
  }
}

export default function DocumentTemplate({ document, searchPhrases }) {
  const {
    source, title, firstParagraph, relatedKeywords: keywords, isNew,
  } = document;
  const siteName = getHostName(source);
  return (
    <div className="flex flex-col bg-white px-4 py-3 gap-y-1">
      <div className="flex justify-between">
        <span className="text-sm text-gray-800 font-semibold" style={{ wordBreak: 'break-word' }}>
          {siteName}
        </span>
        { isNew && (
        <span className="-mt-1 -mr-3 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
          New
        </span>
        )}
      </div>
      <a href={source} className="hover:underline text-sm font-semibold text-blue-800 visited:text-purple-800" target="_blank" rel="noopener noreferrer">
        <h3 className="">
          {title}
        </h3>
      </a>
      <p className="text-sm text-gray-800 inline-block" style={{ wordBreak: 'break-word' }}>
        &ldquo;
        {firstParagraph}
        &rdquo;
      </p>
      {(keywords && keywords.length > 0)
        && (
          <p className="leading-3">
            <span className="text-sm font-medium font-bold text-gray-800 pr-1">Matching phrases:</span>
            <span
              className="text-sm text-gray-800"
            >
              {keywords.filter((keyword) => (searchPhrases.includes(keyword)))
                .join(', ')}
            </span>
          </p>
        )}
    </div>
  );
}
