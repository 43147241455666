import React from 'react';

export default function Loading() {
  return (
    <div className="absolute inset-0 flex justify-center items-center bg-white opacity-90">
      <div
        style={{ borderTopColor: 'transparent' }}
        className="w-12 h-12 border-4 border-blue-500 border-solid rounded-full animate-spin"
      />
    </div>
  );
}
