import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import getErrorMessage from '../services/errorHandler';

const useManagementTable = (props) => {
  const { pageSizeOptions, getRowsfn, geRowsbyInputfn } = props;
  const [pending, setPending] = useState(true);
  const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: pageSizeOptions[0] });
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [notification, setNotification] = useState({ show: false });
  const { state } = useLocation();

  useEffect(() => {
    if (state && state.pagination) setPagination(state.pagination);
    if (state && state.notification) {
      setNotification({ show: true, ...state.notification });
      window.history.replaceState({ ...state, notification: null }, '');
    }
  }, [state, setPagination]);

  const setRowsByPagination = useCallback(async () => {
    setPending(true);
    try {
      const { rows: newRows, totalCount } = await getRowsfn(pagination);
      if (newRows.length === 0 && totalCount > 0) {
        const { pageNumber, pageSize } = pagination;
        if (pageNumber > 1) {
          setPagination({ pageNumber: pageNumber - 1, pageSize });
        }
        return;
      }
      setRows(newRows);
      setCount(totalCount);
      if (errorMessage) setErrorMessage('');
    } catch (error) {
      setRows([]);
      setCount(0);
      setErrorMessage(getErrorMessage(error));
    }
    setPending(false);
  }, [pagination, errorMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!searchInput) setRowsByPagination();
  }, [searchInput, setRowsByPagination]);

  const getSearchResult = useCallback(async () => {
    setPending(true);
    try {
      const result = await geRowsbyInputfn(searchInput);
      setSearchResult(Array.isArray(result) ? result : [result]);
      if (errorMessage) setErrorMessage('');
    } catch (error) {
      setSearchResult([]);
      setErrorMessage(getErrorMessage(error));
    }
    setPending(false);
  }, [searchInput, errorMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const setRowsBySearchResult = useCallback(async () => {
    if (searchResult.length) {
      const { pageNumber, pageSize } = pagination;
      setRows(searchResult.slice((pageNumber - 1) * pageSize, pageNumber * pageSize));
    } else {
      setRows([]);
      if (!errorMessage) setErrorMessage('There is no search result.');
    }
  }, [searchResult, pagination, errorMessage]);

  useEffect(() => {
    if (searchInput) getSearchResult();
  }, [searchInput, getSearchResult]);

  useEffect(() => {
    setCount(searchResult.length);
    if (searchResult.length) {
      setRowsBySearchResult();
    } else {
      setRows([]);
    }
  }, [searchResult, setRowsBySearchResult]);

  const handleSearch = (input) => {
    if (input) {
      setSearchInput(input);
      setPagination({ pageNumber: 1, pageSize: pagination.pageSize });
    }
  };

  const handleClearSearchInput = () => {
    if (searchInput) {
      setSearchInput('');
      setSearchResult([]);
      setPagination({ pageNumber: 1, pageSize: pagination.pageSize });
    }
  };

  const setSuccessNotification = (title, text) => {
    setNotification({
      show: true,
      type: 'Success',
      title,
      text,
    });
  };

  const setErrorNotification = (title, text) => {
    setNotification({
      show: true,
      type: 'Error',
      title,
      text,
    });
  };

  const closeNotification = () => {
    setNotification({ show: false });
  };

  const updateRow = (row, rowIndex) => {
    setRows([...rows.slice(0, rowIndex), row, ...rows.slice(rowIndex + 1)]);
    if (searchResult.length) {
      const index = ((pagination.pageSize * (pagination.pageNumber - 1)) + rowIndex);
      setSearchResult([...searchResult.slice(0, index), row, ...searchResult.slice(index + 1)]);
    }
  };

  const handleUpdateRow = async ({
    rowIndex, successText, errorText, updateFn,
  }) => {
    setPending(true);
    try {
      const row = await updateFn(rows[rowIndex].id);
      updateRow(row, rowIndex);
      setSuccessNotification('Successfully updated!', successText);
    } catch (error) {
      setErrorNotification(getErrorMessage(error), errorText);
    }
    setPending(false);
  };

  const handleDeleteRow = async ({
    rowIndex, successText, errorText, deleteFn,
  }) => {
    setPending(true);
    try {
      await deleteFn(rows[rowIndex].id);
      const { pageNumber, pageSize } = pagination;
      if (searchResult.length) {
        const index = (pageSize * (pageNumber - 1) + rowIndex);
        setSearchResult([...searchResult.slice(0, index), ...searchResult.slice(index + 1)]);
        setCount(count - 1);
        setPending(false);
      }
      if (!(rows.length - 1) && pageNumber > 1) {
        setPagination({ pageNumber: pageNumber - 1, pageSize });
      } else {
        setPagination({ ...pagination });
      }
      setSuccessNotification('Successfully deleted!', successText);
    } catch (error) {
      setErrorNotification(getErrorMessage(error), errorText);
      setPending(false);
    }
  };

  return ({
    pending,
    pagination,
    count,
    rows,
    searchInput,
    searchResult,
    errorMessage,
    notification,
    setPagination,
    setSearchInput,
    handleSearch,
    handleClearSearchInput,
    handleUpdateRow,
    handleDeleteRow,
    closeNotification,
  });
};

export default useManagementTable;
