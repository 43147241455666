import React, { useState, useLayoutEffect } from 'react';
import {
  Link, useParams, useNavigate, useLocation,
} from 'react-router-dom';
import { ChevronRightIcon, ClipboardListIcon } from '@heroicons/react/solid';
import KeywordsAPI from '../../services/KeywordsAPI';
import getErrorMessage from '../../services/errorHandler';
import KeywordConnectionGroup from '../../components/admin/KeywordConnectionGroup';
import EditKeywordForm from '../../components/admin/EditKeywordForm';
import Tabs from '../../components/Tabs';

const pathname = '/admin/keyword-management';

const getTabs = (id) => {
  if (id === 'create') {
    return ([
      { name: 'Keyword Information', disabled: false },
      { name: 'Connections', disabled: true },
    ]);
  }
  return ([
    { name: 'Keyword Information', disabled: false },
    { name: 'Connections', disabled: false },
  ]);
};

export default function EditKeyword() {
  const [currentTab, setCurrentTab] = useState('Keyword Information');
  const [keyword, setKeyword] = useState({});
  const [message, setMessage] = useState({ text: '' });
  const [connections, setConnections] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const tabs = getTabs(id);

  useLayoutEffect(() => {
    if (id !== 'create') {
      KeywordsAPI.getKeywordById(id)
        .then((res) => {
          setKeyword(res);
        })
        .catch(() => {
          setKeyword({});
        });

      KeywordsAPI.getKeywordConnections(id)
        .then((res) => {
          setConnections(res);
        })
        .catch(() => {
          setConnections([]);
        });
    }
  }, [id]);

  const clearMessage = () => setMessage({});

  const handleChangeTab = (tabName) => {
    setCurrentTab(tabName);
    if (message) clearMessage();
  };

  const saveKeyword = async (values, { setSubmitting }) => {
    try {
      const data = await KeywordsAPI.createKeyword(values);
      navigate(`${pathname}/${data.id}`, { replace: true, state });
      setMessage({ type: 'Success', text: 'Successfully saved' });
    } catch (error) {
      setMessage({ type: 'Error', text: getErrorMessage(error) });
      setSubmitting(false);
    }
  };

  const deleteKeyword = async ({ setSubmitting }) => {
    try {
      await KeywordsAPI.deleteKeyword(id);
      navigate(pathname, {
        state: {
          ...state,
          notification: {
            type: 'Success',
            title: 'Successfully deleted',
            text: `Keyword ${keyword.name} has been deleted.`,
          },
        },
      });
    } catch (error) {
      setMessage({ type: 'Error', text: getErrorMessage(error) });
      setSubmitting(false);
    }
  };

  const saveKeywordConnections = async (removedConnections, newConnections, { setSubmitting }) => {
    try {
      await KeywordsAPI.editKeywordConnections({ id, removedConnections, newConnections });
      const res = await KeywordsAPI.getKeywordConnections(id);
      setConnections(res);
      setMessage({ type: 'Success', text: 'Successfully saved' });
    } catch (error) {
      setMessage({ type: 'Error', text: getErrorMessage(error) });
    } finally {
      setSubmitting(false);
    }
  };

  const Content = () => {
    switch (currentTab) {
      case ('Keyword Information'):
        return (
          <EditKeywordForm
            keyword={keyword}
            message={message}
            clearMessage={clearMessage}
            onDelete={deleteKeyword}
            onSave={saveKeyword}
          />
        );
      case ('Connections'):
        return (
          <KeywordConnectionGroup
            initialConnections={connections}
            message={message}
            onSave={saveKeywordConnections}
          />
        );
      default:
        return (<></>);
    }
  };
  return (
    <div className="space-y-6">
      <nav className="flex items-center space-x-4" aria-label="Breadcrumb">
        <Link
          className="flex items-center text-gray-500 hover:text-gray-700"
          to={pathname}
          state={state}
        >
          <ClipboardListIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
          <span className="ml-2 text-sm font-medium">Keywords</span>
        </Link>
        <div className="flex items-center">
          <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
          <span className="ml-4 text-sm font-medium text-gray-500">{keyword.name || 'New Keyword'}</span>
        </div>
      </nav>
      <Tabs
        tabs={tabs}
        currentTab={currentTab}
        onTabChange={handleChangeTab}
      />
      {Content()}
    </div>
  );
}
