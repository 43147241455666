import React, { useState } from 'react';
import { CheckIcon } from '@heroicons/react/outline';
import Pagination from '../../components/table/Pagination';
import AlertModal from '../../components/AlertModal';
import SearchBar from '../../components/table/SearchBar';
import Loading from '../../components/Loading';
import Notification from '../../components/Notification';
import RoleSelect from '../../components/admin/RoleSelect';
import UsersAPI from '../../services/UsersAPI';
import { useManagementTable } from '../../hooks';

const columns = ['Name', 'Email', 'Role', 'Verified'];
const pageSizeOptions = [10, 25, 50];

export default function UserManagement() {
  const [selectedUser, setSelectedUser] = useState({});
  const [modal, setModal] = useState({ open: false });
  const {
    pending,
    pagination,
    count,
    rows,
    errorMessage,
    notification,
    setPagination,
    handleSearch,
    handleClearSearchInput,
    handleUpdateRow,
    handleDeleteRow,
    closeNotification,
  } = useManagementTable({
    pageSizeOptions,
    getRowsfn: UsersAPI.getAllUsers,
    geRowsbyInputfn: UsersAPI.findUsersByInput,
  });

  const handlVerifyUser = (user, rowIndex) => {
    setSelectedUser(user);
    setModal({
      open: true,
      title: 'Verify Account',
      content: 'Are you sure to verify the following account?',
      onConfirm: () => handleUpdateRow({
        rowIndex,
        successText: `User ${user.name} has been verified.`,
        errorText: `User ${user.name} failed to be verified.`,
        updateFn: UsersAPI.adminVerify,
      }),
    });
  };

  const handleToggleAdmin = (user, rowIndex) => {
    setSelectedUser(user);
    const isAdmin = user.admin;
    setModal({
      open: true,
      title: 'Switch Role',
      content: `Are you sure to switch the following account's role to ${isAdmin ? 'Member' : 'Admin'}?`,
      onConfirm: () => handleUpdateRow({
        rowIndex,
        successText: `Role of user ${user.name} has been switched to ${isAdmin ? 'Member' : 'Admin'}.`,
        errorText: `Role of user ${user.name} failed to be switched.`,
        updateFn: UsersAPI.toggleAdmin,
      }),
    });
  };

  const handleDeleteUser = (user, rowIndex) => {
    setSelectedUser(user);
    setModal({
      open: true,
      title: 'Delete Account',
      content: 'Are you sure to delete the following account?',
      onConfirm: () => handleDeleteRow({
        rowIndex,
        successText: `User ${user.name} has been deleted`,
        errorText: `User ${user.name} failed to be deleted.`,
        deleteFn: UsersAPI.deleteUser,
      }),
    });
  };

  return (
    <>
      <div className="flex flex-col gap-5">
        <SearchBar
          placeholder="Search name or email"
          onSearch={handleSearch}
          onClear={handleClearSearchInput}
        />
        <div className="flex flex-col overflow-hidden rounded-lg shadow relative bg-white">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <table className="min-w-full">
                <thead className="border-b border-gray-200">
                  <tr>
                    {columns.map((name) => (
                      <th
                        key={name}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {name}
                      </th>
                    ))}
                    <th scope="col" className="pr-5 py-3 text-right text-gray-500">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {rows
                    .map((user, index) => (
                      <tr key={user.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.name}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{user.email}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          <div className="inline-flex">
                            <span className="block text-sm font-medium text-gray-700">
                              {user.admin ? 'Admin' : 'Member'}
                            </span>
                            <RoleSelect
                              value={user.admin}
                              onChange={() => handleToggleAdmin(user, index)}
                            />
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {user.verified
                            ? <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            : (
                              <button
                                type="button"
                                className="text-blue-600 hover:text-blue-900"
                                onClick={() => handlVerifyUser(user, index)}
                              >
                                Verify
                              </button>
                            )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                          <button
                            type="button"
                            className="text-blue-600 hover:text-blue-900"
                            onClick={() => handleDeleteUser(user, index)}

                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  {(errorMessage.length > 0)
                    && (
                      <tr className="text-center align-middle h-48">
                        <td colSpan="6" className="text-gray-500 text-center align-middle ">{errorMessage}</td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
          <Pagination
            count={count}
            pagination={pagination}
            pageSizeOptions={pageSizeOptions}
            onPaginationChange={setPagination}
          />
          {pending && <Loading />}
        </div>
      </div>
      {
        modal.open && (
          <AlertModal
            open={modal.open}
            title={modal.title}
            content={modal.content}
            onClose={() => { setModal({ open: false }); }}
            onConfirm={() => { modal.onConfirm(); setModal({ open: false }); }}
          >
            <p>{`Name: ${selectedUser.name}`}</p>
            <p>{`E-mail: ${selectedUser.email}`}</p>
          </AlertModal>
        )
      }
      {
        notification.show && (
          <Notification
            show={notification.show}
            type={notification.type}
            text={notification.text}
            title={notification.title}
            onClose={closeNotification}
          />
        )
      }
    </>
  );
}
