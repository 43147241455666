import React from 'react';
import Alert from '../Alert';

export default function ForgotPasswordForm({ onPasswordResetRequest, message }) {
  const [email, setEmail] = React.useState('');
  // const [isValid, setIsValid] = React.useState(true);

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onPasswordResetRequest({ email });
  };

  return (
    <form className="space-y-6" onSubmit={onSubmit}>
      <div>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="Email" className="block text-sm font-medium text-gray-700">
          Email
        </label>
        <div className="mt-1">
          <input
            type="email"
            required
            value={email}
            onChange={onChangeEmail}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>
      </div>
      {
        message && (
          <Alert text={message} type="Error" />
        )
      }
      <div>
        <button
          type="submit"
          className="mt-1 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Submit
        </button>
      </div>
    </form>
  );
}
