import React, { useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

const range = (start, end) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i);
};

export default function Pagination(props) {
  const {
    count, pageSizeOptions, pagination, onPaginationChange,
  } = props;
  const [currentPageNr, setCurrentPageNr] = useState(pagination.pageNumber);
  const [currentPageSize, setCurrentPageSize] = useState(pagination.pageSize);
  const [maxPageNr, setMaxPageNr] = useState(0);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setCurrentPageNr(pagination.pageNumber);
    setCurrentPageSize(pagination.pageSize);
  }, [pagination]);

  useEffect(() => {
    const newMaxPageNr = Math.max(1, Math.ceil(count / currentPageSize));
    setMaxPageNr(newMaxPageNr);
  }, [currentPageSize, count]);

  useEffect(() => {
    if (maxPageNr > 6) {
      let start = range(1, 3);
      let middle = ['middle-ellipsis'];
      let end = range(maxPageNr - 2, maxPageNr);
      if (currentPageNr > 3 && currentPageNr < (maxPageNr - 2)) {
        start = [1, 'start-ellipsis'];
        middle = range(currentPageNr - 1, currentPageNr + 1);
        end = ['end-ellipsis', maxPageNr];
      }
      setItems(start.concat(middle).concat(end));
    } else {
      setItems(range(1, maxPageNr));
    }
  }, [currentPageNr, maxPageNr]);

  const handleChangePageNr = (newPageNr) => {
    onPaginationChange({ pageNumber: newPageNr, pageSize: currentPageSize });
  };

  const handleChangePageSize = (event) => {
    onPaginationChange({ pageNumber: 1, pageSize: +event.target.value });
  };

  const handleBackButtonClick = () => {
    handleChangePageNr(currentPageNr - 1);
  };

  const handleNextButtonClick = () => {
    handleChangePageNr(currentPageNr + 1);
  };

  return (
    <div className="bg-inherit px-4 py-3 flex items-center justify-between sm:px-6 border-t border-gray-200">
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          type="button"
          disabled={currentPageNr === 1}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={handleBackButtonClick}
        >
          Previous
        </button>
        <button
          type="button"
          disabled={currentPageNr === maxPageNr}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={handleNextButtonClick}
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="flex items-center gap-3">
          <div className="flex items-center gap-1">
            <p className="text-sm text-gray-700">Rows per page: </p>
            <select
              className="mt-1 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              defaultValue={currentPageSize}
              onChange={handleChangePageSize}
            >
              {pageSizeOptions.map((option) => (
                <option key={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          {(count > 0)
            ? (
              <p className="text-sm text-gray-700">
                Showing
                <span className="font-medium">
                  {` ${(currentPageNr - 1) * currentPageSize + 1} `}
                </span>
                to
                <span className="font-medium">
                  {` ${Math.min(currentPageNr * currentPageSize, count)} `}
                </span>
                of
                <span className="font-medium">
                  {` ${count} `}
                </span>
                results
              </p>
            )
            : (
              <p className="text-sm text-gray-700">0 result</p>
            )}
        </div>
        <div>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              type="button"
              disabled={currentPageNr === 1}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-50 disabled:opacity-50 disabled:cursor-default"
              onClick={handleBackButtonClick}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {items.map((item) => (
              typeof item === 'number'
                ? (
                  <button
                    key={item}
                    type="button"
                    disabled={item === currentPageNr}
                    className={`${item === currentPageNr ? 'z-10 bg-blue-50 border-blue-500 text-blue-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'} relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                    onClick={() => handleChangePageNr(item)}
                  >
                    {item}
                  </button>
                )
                : (
                  <span key={item} className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                    ...
                  </span>
                )
            ))}
            <button
              type="button"
              disabled={currentPageNr === maxPageNr}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-50 disabled:opacity-50 disabled:cursor-default"
              onClick={handleNextButtonClick}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
