import React from 'react';
import { useFormik } from 'formik';
import Alert from '../Alert';

export default function EditDocumentForm({
  document, message, clearMessage, ...props
}) {
  const {
    dirty, isSubmitting, status, values, setSubmitting, setStatus, ...formik
  } = useFormik({
    enableReinitialize: true,
    initialValues: { language: 'en', ...document },
    onSubmit: () => {
      props.onSave(values, { setSubmitting });
    },
  });

  const handleChange = (e) => {
    formik.handleChange(e);
    if (message) clearMessage();
  };

  const onDelete = () => {
    setSubmitting(true);
    props.onDelete({ setSubmitting });
  };

  return (
    <form className="space-y-8" onSubmit={formik.handleSubmit}>
      <div>
        <div className="mt-6 grid grid-cols-12 gap-y-6 gap-x-4">
          <div className="col-span-8">
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Title
              <div className="mt-1">
                <input
                  type="text"
                  name="title"
                  id="title"
                  required
                  autoComplete="off"
                  value={values.title || ''}
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  onChange={handleChange}
                />
              </div>
            </label>
          </div>
          <div className="col-span-10">
            <label htmlFor="url" className="block text-sm font-medium text-gray-700">
              Url
              <div className="mt-1">
                <input
                  type="text"
                  name="url"
                  id="url"
                  required
                  autoComplete="off"
                  value={values.url || ''}
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  onChange={handleChange}
                />
              </div>
            </label>
          </div>
          <div className="col-span-6">
            <label htmlFor="sitename" className="block text-sm font-medium text-gray-700">
              Site name
              <div className="mt-1">
                <input
                  type="text"
                  name="sitename"
                  id="sitename"
                  required
                  autoComplete="off"
                  value={values.sitename || ''}
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  onChange={handleChange}
                />
              </div>
            </label>
          </div>
          <div className="col-span-3">
            <label htmlFor="language" className="block text-sm font-medium text-gray-700">
              Language
              <div className="mt-1">
                <select
                  id="language"
                  name="language"
                  required
                  autoComplete="off"
                  value={values.language}
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  onChange={handleChange}
                >
                  <option value="en">English</option>
                  <option value="de">German</option>
                </select>
              </div>
            </label>
          </div>
          <div className="col-span-3">
            <label htmlFor="date" className="block text-sm font-medium text-gray-700">
              Date
              <div className="mt-1">
                <input
                  type="text"
                  name="date"
                  id="date"
                  required
                  autoComplete="off"
                  value={values.date || ''}
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  onChange={handleChange}
                />
              </div>
            </label>
          </div>
          <div className="col-span-12">
            <label htmlFor="text" className="block text-sm font-medium text-gray-700">
              Text
              <div className="mt-1">
                <textarea
                  name="text"
                  id="text"
                  rows={4}
                  required
                  value={values.text || ''}
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                  onChange={handleChange}
                />
              </div>
            </label>
          </div>

        </div>
      </div>
      {
        message.type && (
          <Alert type={message.type} text={message.text} />
        )
      }
      <div className="pt-5 border-t">
        {document.id
          && (
            <button
              type="button"
              disabled={isSubmitting}
              className="bg-white py-2 px-4 border border-red-400 rounded-md shadow-sm text-sm font-medium text-red-600 hover:text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              onClick={onDelete}
            >
              Delete
            </button>
          )}
        <div className="float-right">
          <button
            type="submit"
            disabled={isSubmitting || !dirty}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-600 disabled:opacity-50 disabled:cursor-default"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
