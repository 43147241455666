import React, { useEffect } from 'react';
import {
  Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';
import Particles from 'react-tsparticles';
import MediaQuery from 'react-responsive';
import Header from './components/Header';
import Register from './views/Register';
import Login from './views/Login';
import ResetPassword from './views/ResetPassword';
import Verify from './views/Verify';
import User from './views/User';
import AuthService from './services/AuthService';
import particleConfig from './assets/ParticleConfig.json';

function App() {
  const user = AuthService.getCurrentUser();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!user && !['/passwordReset', '/login', '/register', '/verify'].includes(location.pathname)) {
      navigate('/login');
    }
  }, [user, location.pathname, navigate]);

  return (
    <div>
      <Header user={user} />
      <MediaQuery minWidth={800}>
        <Particles
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: -1,
          }}
          params={particleConfig}
        />
      </MediaQuery>
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/passwordReset" element={<ResetPassword />} />
        <Route path="/*" element={<User user={user} />} />
      </Routes>
    </div>
  );
}

export default App;
