import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import StickyBox from 'react-sticky-box';
import SearchBar from '../components/searchbar/SearchBar';
import Alert from '../components/Alert';
import DocumentList from '../components/document/DocumentList';
import ResultGraph3D from '../components/graph/ResultGraph3D';
import ToolTip from '../components/graph/ToolTip';
import EmptySearch from '../components/graph/EmptySearch';
import Toggle from '../components/graph/Toggle';
import ResultGraph2D from '../components/graph/ResultGraph2D';
import pickColor from '../components/graph/utils';
import ResetButton from '../components/graph/ResetButton';
import SearchAPI from '../services/SearchAPI';
import useWindowSize from '../hooks/useWindowSize';

export default function Search() {
  const [message, setMessage] = useState('');
  const [documents, setDocuments] = useState({
    entries: [], colorClusters: new Map(), searchPhrases: [],
  });
  const searchBarRef = useRef(null);
  const [searchItems, setSearchItems] = useState([]);
  const [graphData, setGraphData] = useState({ nodes: [], relationShips: [] });
  const [reformedData, setReformedData] = useState({ nodes: [], links: [] });
  const [mode3D, setMode3D] = useState(true);
  const [multiColor, setMultiColor] = useState(true);
  const [graphColorPalette, setGraphColorPalette] = useState([]);
  const [resetCamera, setResetCamera] = useState(false);
  const windowSize = useWindowSize();

  const setInitialState = () => {
    setDocuments({ entries: [], colorClusters: new Map(), searchPhrases: [] });
    setGraphData({ relationShips: [], nodes: [] });
  };

  useEffect(() => {
    if (graphData.nodes.length !== 0) {
      const colorClusters = new Map();
      const tmpPalette = graphColorPalette;
      const nodes = graphData.nodes.map((n) => {
        const isSearchTerm = searchItems.includes(n.keyword);
        let color;
        if (!multiColor) {
          color = isSearchTerm ? '#ffcd59' : '#7598e6';
        } else {
          color = pickColor(n.color_arr, { red: 255, green: 255, blue: 255 },
            tmpPalette);
        }
        if (colorClusters.has(color)) {
          colorClusters.get(color).push(n.keyword);
        } else {
          colorClusters.set(color, [n.keyword]);
        }
        return ({
          id: n.keyword,
          group: n.color_arr,
          color,
          isSearchTerm,
          // eslint-disable-next-line max-len
          numLinks: graphData.relationShips.reduce((count, v) => (v[0] === n.keyword || v[1] === n.keyword ? count + 1 : count), 0),
        });
      });
      setGraphColorPalette(tmpPalette);
      setDocuments((prevState) => ({
        ...prevState,
        colorClusters,
        searchPhrases: searchItems,
      }));
      const links = graphData.relationShips.map((r) => ({
        source: r[0],
        target: r[1],
        value: r[2],
      }));
      setReformedData((prevState) => {
        const prevNodes = prevState.nodes;
        const prevLinks = prevState.links;
        const remainingNodes = prevNodes.filter((n) => nodes.some((n2) => n2.id === n.id));
        remainingNodes.forEach((node) => {
          // eslint-disable-next-line no-param-reassign
          node.isSearchTerm = searchItems.includes(node.id);
          // eslint-disable-next-line no-param-reassign
          node.color = nodes.find((n) => n.id === node.id).color;
        });
        const remainingLinks = prevLinks.filter((l) => links
          .some((l2) => l2.source.id === l.source.id && l2.target.id === l.target.id));
        const newNodes = nodes.filter((n) => !remainingNodes.some((n2) => n2.id === n.id));
        const newLinks = links
          .filter((l) => !remainingLinks
            .some((l2) => l2.source.id === l.source.id && l2.target.id === l.target.id));
        return {
          nodes: [...remainingNodes, ...newNodes],
          links: [...remainingLinks, ...newLinks],
        };
      });
    }
  }, [graphData, searchItems, graphColorPalette, multiColor]);

  const removeNode = (node) => {
    setGraphData((prevState) => {
      const prevNodes = prevState.nodes;
      const prevLinks = prevState.relationShips;
      const remainLinks = prevLinks.filter((link) => link[1] !== node.id && link[0] !== node.id);
      const index = prevNodes.findIndex((n) => n.keyword === node.id);
      if (index > -1) {
        prevNodes.splice(index, 1);
      }
      return {
        relationShips: remainLinks,
        nodes: prevNodes,
      };
    });
  };

  const handleSearch = (items) => {
    if (!items.length) {
      setInitialState();
    } else {
      if (message) { setMessage(''); }
      SearchAPI.search(items)
        .then(({ graphData: newGraphData, relatedDocuments }) => {
          if (newGraphData && relatedDocuments) {
            setGraphData(newGraphData);
            setDocuments((prevState) => {
              const newTitles = relatedDocuments.map((e) => e.title);
              const remainingTitles = prevState.entries
                .map((d) => d.title)
                .filter((d) => newTitles.includes(d));

              const annotatedDocuments = relatedDocuments.map((d) => ({
                ...d,
                isNew: remainingTitles.length > 0 && !remainingTitles.includes(d.title),
              }));

              return ({ ...prevState, entries: annotatedDocuments, colorClusters: [] });
            });
            setSearchItems(items);
          } else {
            throw new Error('Incomplete Result');
          }
        })
        .catch((error) => {
          const resMessage = (error.response
            && error.response.data
            && error.response.data.message)
            || error.message
            || error.toString();
          setMessage(resMessage);
          setInitialState();
        });
    }
  };

  const addNewItem = (item, newSearch) => {
    if (!item.isSearchTerm) {
      searchBarRef.current.addNewItem({ name: item.id, type: 'valid' });
      if (newSearch) {
        searchBarRef.current.performSearch();
      }
    }
  };

  const removeKeyword = (item) => {
    searchBarRef.current.removeItem(item);
    setTimeout(() => {
      searchBarRef.current.performSearch();
    }, 50);
  };

  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const graphContainer = useCallback((node) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, [windowSize]);

  const documentContainer = useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height - 125);
    }
  }, [windowSize]);

  const [toolTip, setToolTip] = useState({
    x: 0,
    y: 0,
    node: null,
    visible: false,
    isSearchKeyword: false,
  });

  function hideToolTip() {
    setToolTip({
      x: 0,
      y: 0,
      content: '',
      visible: false,
      isSearchKeyword: false,
    });
  }

  return (
    <div className="w-full lg:flex lg:items-start -my-6">
      <div className="lg:w-2/3 max-w-7xl flex flex-col py-6" style={{ minHeight: 'calc(100vh - 50px)' }}>
        <div className="pb-4">
          <SearchBar
            ref={searchBarRef}
            onSearch={handleSearch}
          />
          {message && (<Alert type="Attention" text={message} />)}
        </div>
        <div ref={graphContainer} className="relative flex-grow h-full bg-white border border-gray-200 rounded-lg overflow-hidden shadow-sm">
          {reformedData.nodes.length === 0 ? <EmptySearch /> : (
            <div>
              <div className="absolute inset-x-0 top-0 flex flex-row bg-graph-bg justify-between" style={{ zIndex: 99999 }}>
                <div className="flex flex-row">
                  <Toggle enabled={mode3D} setEnabled={setMode3D} label="3D mode" />
                  <Toggle enabled={multiColor} setEnabled={setMultiColor} label="Group colors" />
                </div>
                <ResetButton onClick={() => { setResetCamera(true); }} />
              </div>
              {mode3D ? (
                <ResultGraph3D
                  onNodeClick={(node) => addNewItem(node, true)}
                  onNodeRightClick={(e) => {
                    setToolTip({
                      x: e.x,
                      y: e.y,
                      node: e.node,
                      visible: true,
                      isSearchKeyword: searchItems.includes(e.node.id),
                    });
                  }}
                  otherClick={() => hideToolTip()}
                  containerWidth={width}
                  containerHeight={height}
                  graphData={reformedData}
                  isMultiColored={multiColor}
                  resetCamera={resetCamera}
                  setResetCamera={setResetCamera}
                />
              ) : (
                <ResultGraph2D
                  onNodeClick={(node) => addNewItem(node, true)}
                  onNodeRightClick={(e) => {
                    setToolTip({
                      x: e.x,
                      y: e.y,
                      node: e.node,
                      visible: true,
                      isSearchKeyword: searchItems.includes(e.node.keyword),
                    });
                  }}
                  otherClick={() => hideToolTip()}
                  containerWidth={width}
                  containerHeight={height}
                  graphData={reformedData}
                  isMultiColored={multiColor}
                  resetCamera={resetCamera}
                  setResetCamera={setResetCamera}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <StickyBox style={{ flex: 1, height: 'calc(100vh - 48px)' }}>
        <div ref={documentContainer} className="h-full max-w-full 2xl:max-w-xl pb-6 lg:py-6 lg:pl-4 2xl:pl-6">
          <div className="h-full overflow-y-auto bg-white border border-gray-200 rounded-lg shadow-sm">
            <DocumentList documents={documents} />
          </div>
        </div>
      </StickyBox>
      {/* eslint-disable-next-line max-len */}
      <ToolTip toolTip={toolTip} addItem={addNewItem} hide={() => hideToolTip()} removeNode={removeNode} removeKeyword={removeKeyword} />
    </div>
  );
}
