import React, { Fragment } from 'react';

import { Transition } from '@headlessui/react';

export default function ToolTip({
  toolTip, addItem, hide, removeNode, removeKeyword,
}) {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
      show={toolTip.visible}
    >
      <div
        style={{
          position: 'absolute',
          top: toolTip.y,
          left: toolTip.x,
          background: 'white',
          width: '190px',
          padding: '5px',
          zIndex: '99',
          borderRadius: '15px',
          boxShadow: '3px 5px 5px rgb(235, 235, 235)',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: 'rgba(230, 230, 230, 0.5)',
        }}
        hidden={!toolTip.visible}
      >
        <div
          className="block py-2 px-5 text-sm tracking-wide font-semibold text-gray-800"
          aria-hidden="true"
        >
          {toolTip.node && toolTip.node.id}
        </div>
        <hr className="border-t border-gray-200 mx-2" aria-hidden="true" />
        {
          !toolTip.isSearchKeyword && (
            <div
              className="block py-2 px-5 cursor-pointer text-sm tracking-wide font-medium text-gray-600 hover:text-blue-600"
              aria-hidden="true"
              onClick={() => { addItem(toolTip.node, false); hide(); }}
            >
              Add to search
            </div>
          )
        }
        <div
          className="block py-2 px-5 cursor-pointer text-sm tracking-wide font-medium text-gray-600 hover:text-blue-600"
          aria-hidden="true"
          onClick={() => { removeNode(toolTip.node); hide(); }}
        >
          Remove from graph
        </div>
        {
          toolTip.isSearchKeyword && (
            <div
              className="block py-2 px-5 cursor-pointer text-sm tracking-wide font-medium text-gray-600 hover:text-blue-600"
              aria-hidden="true"
              onClick={() => { removeKeyword(toolTip.node); hide(); }}
            >
              Remove keyword from search
            </div>
          )
        }
        <hr className="border-t border-gray-200 mx-2" aria-hidden="true" />
        <div
          className="block py-2 px-5 cursor-pointer text-sm tracking-wide font-medium text-gray-600 hover:text-blue-600"
          aria-hidden="true"
          onClick={() => { hide(); }}
        >
          Close
        </div>
      </div>
    </Transition>
  );
}
