import React, { useRef } from 'react';
import { SearchIcon } from '@heroicons/react/solid';
import { useAutoComplete } from '../../hooks';
import AutoCompleteOptions from '../searchbar/AutoCompleteOptions';

export default function KeywordSearchBar({ placeholder, onSearch, onClear }) {
  const {
    value,
    setValue,
    handleInputChange,
    options,
    activeOptionIndex,
    showMenu,
    closeMenu,
    handleArrowKeyDown,
  } = useAutoComplete({ initialValue: '', initialOptionIndex: -1 });

  const inputRef = useRef(null);
  const focusInput = () => { inputRef.current.focus(); };

  const handleKeyDown = (e) => {
    const { key } = e;
    switch (key) {
      case 'ArrowDown':
      case 'ArrowUp':
        e.preventDefault();
        handleArrowKeyDown(key);
        break;
      case 'Enter':
        e.preventDefault();
        onSearch(value);
        closeMenu();
        break;
      default:
        break;
    }
  };

  const handleBlur = () => {
    closeMenu();
    if (!value) onClear();
  };

  const handleOptionSelect = (option) => {
    setValue(option);
    setTimeout(() => focusInput(), 0);
    closeMenu();
    onSearch(option);
  };

  return (
    <div className="w-full max-w-lg bg-white rounded-md">
      <div className="relative">
        <div className="relative">
          <input
            className="block w-full pr-10 py-3 border border-gray-300 rounded-md leading-5 bg-white shadow-sm placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-600 focus:border-blue-600 sm:text-sm"
            placeholder={placeholder}
            type="search"
            ref={inputRef}
            value={value}
            onChange={handleInputChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          />
          <button
            disabled={!value}
            type="button"
            className={`absolute inset-y-0 right-0 pr-2.5 items-center rounded-r-md ${value ? 'text-blue-600' : 'text-gray-400'}`}
            onClick={() => { onSearch(value); closeMenu(); }}
          >
            <SearchIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        {(showMenu)
          && (
            <AutoCompleteOptions
              activeIndex={activeOptionIndex}
              options={options}
              onSelect={handleOptionSelect}
            />
          )}
      </div>
    </div>
  );
}
