import React from 'react';
import { ReactComponent as FrameIcon } from '../../assets/frame.svg';

export default function ResetButton({ onClick }) {
  return (
    <button className="pt-3 px-3" onClick={onClick} type="button">
      <FrameIcon />
    </button>
  );
}
