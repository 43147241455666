import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import ResetPasswordForm from '../components/forms/ResetPasswordForm';
import AuthService from '../services/AuthService';

export default function ResetPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const handleResetPassword = (newPassword) => {
    const { token } = queryString.parse(location.search);
    AuthService.passwordReset({ token, password: newPassword }).then(
      () => {
        navigate('/login');
      },
      (error) => {
        const resMessage = (error.response
                    && error.response.data
                    && error.response.data.message)
                || error.message
                || error.toString();
        setErrorMessage(resMessage);
      },
    );
  };

  return (
    <ResetPasswordForm
      onResetPassword={handleResetPassword}
      message={errorMessage}
    />
  );
}
