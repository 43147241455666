import React, { useState, useLayoutEffect } from 'react';
import SearchSettingsForm from '../components/forms/SearchSettingsForm';
import SettingsAPI from '../services/SettingsAPI';

export default function UserSettings() {
  const [searchSettings, setSearchSettings] = useState({});

  useLayoutEffect(() => {
    async function getUserSearchSettings() {
      const res = await SettingsAPI.getUserSearchSettings();
      setSearchSettings(res);
    }
    getUserSearchSettings();
  }, []);

  const changeUserSearchSetting = async (values) => {
    try {
      const data = await SettingsAPI.changeUserSearchSettings(values);
      setSearchSettings(data);
      return ({ type: 'Success', text: 'Successfully saved' });
    } catch (error) {
      return ({ type: 'Error', text: '' });
    }
  };

  return (
    <div className="flex flex-col sm:px-6 lg:px-8">
      {/* <div className="sm:w-full sm:max-w-md"> */}
      <p className="text-lg font-bold text-blue-600">Search Settings</p>
      {/* </div> */}
      <div className="mt-8 w-full">
        <SearchSettingsForm
          initialValues={searchSettings}
          handleSubmit={changeUserSearchSetting}
        />
      </div>
    </div>
  );
}
