import axios from 'axios';
import qs from 'qs';

const authHeader = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.token) {
    return { 'x-access-token': user.token };
  }
  return {};
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers = { ...authHeader() };

axios.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error && error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem('user');
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  },
);

function get(url, params) {
  return axios({
    method: 'get',
    url,
    params,
    paramsSerializer: () => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
}

function post(url, data) {
  return axios({
    method: 'post',
    url,
    data,
  });
}

export default {
  get,
  post,
};
