import React from 'react';
import { CheckCircleIcon, ExclamationIcon, XCircleIcon } from '@heroicons/react/outline';

// Alert component
// Types:
// "Attention" for yellow alert box with exclamation mark
// "Error" for red alert box with X mark
// "Success" for green alert box with check mark
export default function Alert({ text, type }) {
  return (
    {
      Attention:
  <div className="rounded-md bg-yellow-50 p-4 mt-3">
    <div className="flex">
      <div className="flex-shrink-0">
        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-yellow-800">{text}</h3>
      </div>
    </div>
  </div>,
      Error:
  <div className="rounded-md bg-red-50 p-4 mt-3">
    <div className="flex">
      <div className="flex-shrink-0">
        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-red-800">{text}</h3>
      </div>
    </div>
  </div>,
      Success:
  <div className="rounded-md bg-green-50 p-4 mt-3">
    <div className="flex">
      <div className="flex-shrink-0">
        <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-green-800">{text}</h3>
      </div>
    </div>
  </div>,
    }[type]
  );
}
