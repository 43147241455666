import { useState } from 'react';
import SearchAPI from '../services/SearchAPI';
import useOptionMenu from './useOptionMenu';

const useAutoComplete = ({ initialValue, initialOptionIndex }) => {
  const [value, setValue] = useState(initialValue);

  const {
    options,
    setOptions,
    activeOptionIndex,
    handleArrowKeyDown,
    showMenu,
    openMenu,
    closeMenu,
  } = useOptionMenu({
    initialOptionIndex,
    handleActiveOptionChange(newActiveOptionIndex) {
      if (newActiveOptionIndex > -1) setValue(options[newActiveOptionIndex]);
      else setValue('');
    },
  });

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    setValue(userInput);
    if (userInput.length) {
      SearchAPI.autoComplete(userInput.toLowerCase())
        .then((data) => {
          if (data && data.length) {
            openMenu();
            setOptions(data);
          } else {
            throw new Error('No Result');
          }
        })
        .catch(() => {
          setOptions([]);
          closeMenu();
        });
    } else {
      setOptions([]);
      closeMenu();
    }
  };

  return ({
    value,
    setValue,
    handleInputChange,
    options,
    activeOptionIndex,
    handleArrowKeyDown,
    showMenu,
    closeMenu,
  });
};

export default useAutoComplete;
