import React, {
  forwardRef, useImperativeHandle, useRef,
} from 'react';
import AutocompleteOptions from './AutoCompleteOptions';
import { useAutoComplete } from '../../hooks';

const AutoComplete = forwardRef((props, ref) => {
  const {
    onInputChange, onInputComplete, onKeyDown,
  } = props;
  const {
    value,
    options,
    activeOptionIndex,
    setValue,
    handleInputChange,
    handleArrowKeyDown,
    showMenu,
    closeMenu,
  } = useAutoComplete({ initialValue: '', initialOptionIndex: 0 });

  const inputRef = useRef(null);
  const focusInput = () => { inputRef.current.focus(); };
  // const selectInput = () => { inputRef.current.select(); };

  useImperativeHandle(ref, () => ({
    focusInput,
  }));

  const handleChange = (e) => {
    if (e.target.value) onInputChange();
    handleInputChange(e);
  };

  const handleBlur = () => {
    closeMenu();
    setValue('');
  };

  const onComplete = (option) => {
    closeMenu();
    setValue('');
    onInputComplete(option);
  };

  const handleKeyDown = (e) => {
    if (!value.length) {
      onKeyDown(e);
    } else {
      switch (e.key) {
        case 'ArrowDown':
        case 'ArrowUp':
          e.preventDefault();
          if (options.length) handleArrowKeyDown(e.key);
          break;
        case 'Enter':
          if (options.length) onComplete({ name: options[activeOptionIndex], type: 'valid' });
          else onComplete({ name: value, type: 'invalid' });
          break;
        default:
          break;
      }
    }
  };

  const handleOptionSelect = (option) => {
    onComplete({ name: option, type: 'valid' });
    setTimeout(() => focusInput(), 0);
  };

  return (
    <div className="relative pl-1 max-w-full">
      <span className="w-full text sm:text-sm invisible">
        {value.length ? value : 'Enter Keyword'}
      </span>
      <input
        type="text"
        id="search-input"
        ref={inputRef}
        value={value}
        placeholder="Enter Keyword"
        autoComplete="off"
        className="absolute inset-x-0 top-0 inline-block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      />
      {showMenu
        && (
          <AutocompleteOptions
            activeIndex={activeOptionIndex}
            options={options}
            onSelect={handleOptionSelect}
          />
        )}
    </div>
  );
});

export default AutoComplete;
