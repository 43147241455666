import React from 'react';

function EmptySearch() {
  const Icon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-28 w-28" viewBox="0 0 20 20" fill="#2563eb" width="100%">
      <path
        d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2h-1.528A6 6 0 004 9.528V4z"
      />
      <path
        fillRule="evenodd"
        d="M8 10a4 4 0 00-3.446 6.032l-1.261 1.26a1 1 0 101.414 1.415l1.261-1.261A4 4 0 108 10zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
        clipRule="evenodd"
      />
    </svg>
  );
  return (
    <div className="absolute inset-0 flex flex-col justify-center items-center">
      <Icon />
      <h2 className="text-xl font-extrabold tracking-tight text-blue-600 sm:text-3xl">
        No results yet!
      </h2>
      <p className="mt-3 max-w-3xl text-lg leading-6 text-gray-500">
        Type in keywords to start your search
      </p>
    </div>
  );
}

export default EmptySearch;
