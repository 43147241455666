import React, { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { UserCircleIcon } from '@heroicons/react/solid';
import AuthService from '../services/AuthService';

const navigation = [
  { name: 'Your Profile', href: '/user/profile' },
  { name: 'Settings', href: '/user/settings' },
];

function Header(props) {
  const { user } = props;
  const navigate = useNavigate();

  return (
    <header className="bg-blue-600 shadow">
      <div className="w-full py-2 px-5 flex items-center justify-between border-b border-blue-500 lg:border-none">
        <div className="inline-flex items-center gap-1 text-white">
          <button
            onClick={() => navigate(user ? '/' : '/login')}
            className="text-center text-2xl font-extrabold italic text-white"
            type="button"
          >
            GING
          </button>
        </div>

        {!user
          ? (
            <div className="ml-10 space-x-4">
              <Link
                to="/login"
                className="inline-block bg-blue-500 py-1 px-4 border border-transparent rounded-md text-base font-medium text-white hover:bg-opacity-75"
              >
                Sign in
              </Link>
              <Link
                to="/register"
                className="inline-block bg-white py-1 px-4 border border-transparent rounded-md text-base font-medium text-blue-600 hover:bg-blue-50"
              >
                Sign up
              </Link>
            </div>
          )
          : (
            <Menu as="div" className="ml-3 relative" style={{ zIndex: 100000 }}>
              <div>
                <Menu.Button className="max-w-xs flex items-center text-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <UserCircleIcon className="h-8 w-8" aria-hidden="true" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                  {navigation.map((item) => (
                    <Menu.Item key={item.name}>
                      <Link
                        to={item.href}
                        className="block py-2 px-5 text-sm tracking-wide font-medium text-gray-600 hover:text-blue-600"
                      >
                        {item.name}
                      </Link>
                    </Menu.Item>
                  ))}
                  <hr className="border-t border-gray-200 mx-2" aria-hidden="true" />
                  <Menu.Item>
                    <div
                      className="block py-2 px-5 cursor-pointer text-sm tracking-wide font-medium text-gray-600 hover:text-blue-600"
                      aria-hidden="true"
                      onClick={AuthService.logout}
                    >
                      Sign Out
                    </div>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          )}
      </div>
    </header>
  );
}

export default Header;
