import React from 'react';

export default function AutoCompleteOptions({ activeIndex, options, onSelect }) {
  return (
    <ul
      className="absolute left-0 rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      style={{ minWidth: '16rem', zIndex: 100000 }}
    >
      {options.map((option, index) => (
        <li
          key={`option ${option}`}
          aria-hidden="true"
          className={`${index === activeIndex ? 'bg-gray-200' : ''} w-full px-3 py-1.5 text-sm  text-gray-900 cursor-pointer hover:bg-gray-200`}
          onMouseDown={() => onSelect(option)}
        >
          {option}
        </li>
      ))}
    </ul>
  );
}
