import HttpService from './HttpService';

const API_URL = '/settings';

const getUserSearchSettings = () => HttpService
  .get(`${API_URL}/getUserSearchSettings`, {});

const changeUserSearchSettings = ({ nodeLimit, docLimit, depth }) => HttpService
  .post(`${API_URL}/changeUserSearchSettings`, { nodeLimit, docLimit, depth });

export default {
  getUserSearchSettings,
  changeUserSearchSettings,
};
