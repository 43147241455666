import React, { useState } from 'react';
import RegisterForm from '../components/forms/RegisterForm';
import AuthService from '../services/AuthService';
import Modal from '../components/Modal';

function Register() {
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const handleRegister = ({
    name, email, password,
  }) => {
    AuthService.register(name, email, password).then(
      (response) => {
        setMessage(response.data.message);
        if (response.status === 201) {
          setOpen(true);
        }
      },
      (error) => {
        const resMessage = (error.response
                    && error.response.data
                    && error.response.data.message)
                || error.message
                || error.toString();
        setMessage(resMessage);
      },
    );
  };
  return (
    <div>
      <Modal open={open} onClose={() => setOpen(false)} title="Your registration is successful" content="We have sent a verification email to your address, please verify your account!" button="continue" type="email" />
      <RegisterForm
        onRegister={handleRegister}
        message={message}
      />
    </div>
  );
}

export default Register;
