import HttpService from './HttpService';

const API_PATH = '/search';

const autoComplete = (q) => HttpService.get(`${API_PATH}/autoComplete`, { q });

const search = (keywords) => HttpService.get(`${API_PATH}/search`, { keywords });

export default {
  autoComplete,
  search,
};
