import React, { useState, useEffect } from 'react';
import {
  Link, useParams, useNavigate, useLocation,
} from 'react-router-dom';
import { ChevronRightIcon, DocumentTextIcon } from '@heroicons/react/solid';
import DocumentsAPI from '../../services/DocumentsAPI';
import getErrorMessage from '../../services/errorHandler';
import KeywordConnectionGroup from '../../components/admin/KeywordConnectionGroup';
import EditDocumentForm from '../../components/admin/EditDocumentForm';
import Tabs from '../../components/Tabs';

const pathname = '/admin/document-management';

const getTabs = (id) => {
  if (id === 'create') {
    return ([
      { name: 'Document Information', disabled: false },
      { name: 'Connections', disabled: true },
    ]);
  }
  return ([
    { name: 'Document Information', disabled: false },
    { name: 'Connections', disabled: false },
  ]);
};

export default function EditDocument() {
  const [currentTab, setCurrentTab] = useState('Document Information');
  const [document, setDocument] = useState({});
  const [connections, setConnections] = useState([]);
  const [message, setMessage] = useState({ text: '' });
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const tabs = getTabs(id);

  useEffect(() => {
    if (id !== 'create') {
      DocumentsAPI.getDocumentById(id)
        .then((res) => {
          setDocument(res);
        })
        .catch(() => {
          setDocument([]);
        });

      DocumentsAPI.getKeywordConnections(id)
        .then((res) => {
          setConnections(res);
        })
        .catch(() => {
          setConnections([]);
        });
    }
  }, [id]);

  const clearMessage = () => setMessage({});

  const handleChangeTab = (tabName) => {
    setCurrentTab(tabName);
    if (message) clearMessage();
  };

  const saveDocument = async (values, { setSubmitting }) => {
    try {
      if (id !== 'create') {
        const data = await DocumentsAPI.editDocument(values);
        setDocument(data);
        setMessage({ type: 'Success', text: 'Successfully saved' });
      } else {
        const data = await DocumentsAPI.createDocument(values);
        navigate(`${pathname}/${data.id}`, { replace: true, state });
        setMessage({ type: 'Success', text: 'Successfully created' });
      }
    } catch (error) {
      setMessage({ type: 'Error', text: getErrorMessage(error) });
      setSubmitting(false);
    }
  };

  const deleteDocument = async ({ setSubmitting }) => {
    try {
      await DocumentsAPI.deleteDocument(id);
      navigate(pathname, {
        state: {
          ...state,
          notification: {
            type: 'Success',
            title: 'Successfully deleted',
            text: `Document ${document.title} has been deleted.`,
          },
        },
      });
    } catch (error) {
      setMessage({ type: 'Error', text: getErrorMessage(error) });
      setSubmitting(false);
    }
  };

  const saveKeywordConnections = async (removedConnections, newConnections, { setSubmitting }) => {
    try {
      await DocumentsAPI.editKeywordConnections({ id, removedConnections, newConnections });
      const res = await DocumentsAPI.getKeywordConnections(id);
      setConnections(res);
      setMessage({ type: 'Success', text: 'Successfully saved' });
    } catch (error) {
      setMessage({ type: 'Error', text: getErrorMessage(error) });
    } finally {
      setSubmitting(false);
    }
  };

  const Content = () => {
    switch (currentTab) {
      case ('Document Information'):
        return (
          <EditDocumentForm
            document={document}
            message={message}
            clearMessage={clearMessage}
            onDelete={deleteDocument}
            onSave={saveDocument}
          />
        );
      case ('Connections'):
        return (
          <KeywordConnectionGroup
            initialConnections={connections}
            message={message}
            onSave={saveKeywordConnections}
          />
        );
      default:
        return (<></>);
    }
  };

  return (
    <div className="space-y-6">
      <nav className="flex items-center space-x-4" aria-label="Breadcrumb">
        <Link
          className="flex items-center text-gray-500 hover:text-gray-700"
          to={pathname}
          state={state}
        >
          <DocumentTextIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
          <span className="ml-2 text-sm font-medium">Documents</span>
        </Link>
        <div className="flex items-center">
          <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
          <span className="ml-4 text-sm font-medium text-gray-500">{document.title || 'New Document'}</span>
        </div>
      </nav>
      <Tabs
        tabs={tabs}
        currentTab={currentTab}
        onTabChange={handleChangeTab}
      />
      {Content()}
    </div>
  );
}
