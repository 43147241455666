import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/users`,
});

const register = (name, email, password) => axiosInstance.post('/register', {
  name,
  email,
  password,
});

const login = (email, password) => axiosInstance
  .post('/login', {
    email,
    password,
  })
  .then((response) => {
    const accessToken = response.headers['x-access-token'];
    if (accessToken) {
      localStorage.setItem('user', JSON.stringify({ ...response.data, token: accessToken }));
    } else {
      throw new Error('We couldn\'t log you in right now :/');
    }
    return response.data;
  });

const verifyEmail = (token) => axiosInstance
  .post('/verify', { token });

const edit = (token, email, name, password) => axiosInstance
  .post('/edit', {
    token,
    email,
    name,
    password,
  });

const changePassword = (token, oldPassword, newPassword) => axiosInstance
  .post('/changePassword', { token, oldPassword, newPassword });

const passwordResetRequest = ({ email }) => axiosInstance
  .post('/passwordResetRequest', { email });

const passwordReset = ({ token, password }) => axiosInstance
  .post('/passwordReset', { token, password });

const logout = () => {
  localStorage.removeItem('user');
  window.location.href = '/login';
};

const getCurrentUser = () => JSON.parse(localStorage.getItem('user'));

export default {
  register,
  login,
  verifyEmail,
  logout,
  getCurrentUser,
  edit,
  changePassword,
  passwordResetRequest,
  passwordReset,
};
