import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  HomeIcon, CogIcon, UserCircleIcon, LogoutIcon, UsersIcon, DocumentTextIcon, ClipboardListIcon,
} from '@heroicons/react/outline';
import AuthService from '../services/AuthService';

const navigation = (admin) => (admin
  ? [
    { name: 'Home', href: '/', icon: HomeIcon },
    { name: 'Users', href: '/admin/user-management', icon: UsersIcon },
    { name: 'Documents', href: '/admin/document-management', icon: DocumentTextIcon },
    { name: 'Keywords', href: '/admin/keyword-management', icon: ClipboardListIcon },
  ]
  : [
    { name: 'Home', href: '/', icon: HomeIcon },
  ]);

const secondaryNavigation = [
  { name: 'Your Profile', href: '/user/profile', icon: UserCircleIcon },
  { name: 'Settings', href: '/user/settings', icon: CogIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Sidebar({ user }) {
  const { pathname } = useLocation();
  return (
    <>
      <nav>
        <div className="space-y-1">
          {navigation(user.admin).map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className={classNames(
                (item.href === pathname) ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:text-gray-900 hover:bg-white',
                'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
              )}
              aria-current={item.current ? 'page' : undefined}
            >
              <item.icon
                className={classNames(
                  (item.href === pathname) ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                  'flex-shrink-0 h-6 w-6',
                )}
                aria-hidden="true"
              />
              {/* {item.name} */}
              <div className="hidden xl:inline-block xl:flex-shrink-0 flex-shrink-0 px-3">
                {item.name}
              </div>
            </Link>
          ))}
        </div>
        <hr className="border-t border-gray-300 my-4" aria-hidden="true" />
        <div className="space-y-1">
          {secondaryNavigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className={classNames(
                (item.href === pathname) ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:text-gray-900 hover:bg-white',
                'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
              )}
              aria-current={item.current ? 'page' : undefined}
            >
              <item.icon
                className={classNames(
                  (item.href === pathname) ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                  'flex-shrink-0 h-6 w-6',
                )}
                aria-hidden="true"
              />
              <div className="hidden xl:inline-block xl:flex-shrink-0 px-3">
                {item.name}
              </div>
            </Link>
          ))}
        </div>
      </nav>
      <hr className="border-t border-gray-300 mt-6 mb-4" aria-hidden="true" />
      <button
        type="button"
        className="text-gray-700 hover:text-gray-900 hover:bg-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
        onClick={AuthService.logout}
      >
        <LogoutIcon
          className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-6 w-6"
          aria-hidden="true"
        />
        <div className="hidden xl:inline-block xl:flex-shrink-0 px-3">
          Sign Out
        </div>
      </button>
    </>
  );
}
