const pickColor = (group, colorBase, colorPalette) => {
  const color = colorPalette.find((a) => a.group === group);
  if (!color) {
    const red = (Math.floor(Math.random() * 256) + colorBase.red) / 2;
    const green = (Math.floor(Math.random() * 256) + colorBase.green) / 2;
    const blue = (Math.floor(Math.random() * 256) + colorBase.blue) / 2;
    const ret = `rgb(${red}, ${green}, ${blue})`;
    colorPalette.push({ group, color: ret });
    return ret;
  }
  return color.color;
};
export default pickColor;
