import HttpService from './HttpService';

const API_PATH = '/history';

const getUserHistory = () => HttpService
  .get(`${API_PATH}/getUserHistory`, {});

const addToHistory = (keywords) => HttpService
  .post(`${API_PATH}/addToHistory`, { keywords });

const deleteHistoryByTimestamp = (timestamp) => HttpService
  .post(`${API_PATH}/deleteHistoryByTimestamp`, { timestamp });

export default {
  getUserHistory,
  addToHistory,
  deleteHistoryByTimestamp,
};
