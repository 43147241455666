import React, { useState } from 'react';
import { useFormik } from 'formik';
import Alert from '../Alert';

export default function SearchSettingsForm({ initialValues, handleSubmit }) {
  const [message, setMessage] = useState({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nodeLimit: Number(initialValues.nodeLimit),
      docLimit: Number(initialValues.docLimit),
      depth: Number(initialValues.depth),
    },
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values)
        .then((res) => {
          setMessage(res);
          setSubmitting(false);
        });
    },
  });

  const handleChange = (e) => {
    formik.handleChange(e);
    if (message) setMessage({});
  };

  return (
    <form className="space-y-8" onSubmit={formik.handleSubmit}>
      <div>
        <label htmlFor="nodeLimit" className="block text-sm font-medium text-gray-700">
          Node Limit
          <div className="mt-1 max-w-xs">
            <input
              type="number"
              name="nodeLimit"
              id="nodeLimit"
              min="1"
              max="10"
              required
              onChange={handleChange}
              value={formik.values.nodeLimit || ''}
              className="w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </label>
        <p className="mt-2 text-sm text-gray-500">
          Number of related phrases to display for each search term (min 1, max 10)
        </p>
      </div>
      <div>
        <label htmlFor="docLimit" className="block text-sm font-medium text-gray-700">
          Document Limit
          <div className="mt-1 max-w-xs">
            <input
              type="number"
              name="docLimit"
              id="docLimit"
              min="1"
              max="50"
              required
              onChange={handleChange}
              value={formik.values.docLimit || ''}
              className="w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </label>
        <p className="mt-2 text-sm text-gray-500">
          Number of related documents to display (min 1, max 50)
        </p>
      </div>
      <div>
        <label htmlFor="depth" className="block text-sm font-medium text-gray-700">
          Depth
          <div className="mt-1 max-w-xs">
            <input
              type="number"
              name="depth"
              id="depth"
              min="1"
              max="3"
              required
              onChange={handleChange}
              value={formik.values.depth || ''}
              className="w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </label>
        <p className="mt-2 text-sm text-gray-500">
          Number of related keyword-layers to display (min 1, max 3)
        </p>
      </div>

      <div className="max-w-xs">
        <div className="mb-3">
          {message.type && <Alert type={message.type} text={message.text} />}
        </div>
        <button
          type="submit"
          disabled={formik.isSubmitting || !formik.dirty}
          className="w-full inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-600 disabled:opacity-50 disabled:cursor-default"
        >
          Save
        </button>
      </div>
    </form>
  );
}
