import React, { useState } from 'react';
import { SearchIcon } from '@heroicons/react/solid';

export default function SearchBar({ placeholder, onSearch, onClear }) {
  const [input, setInput] = useState('');

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') onSearch(input);
  };

  const handleBlur = () => {
    if (!input) onClear();
  };

  return (
    <div className="w-full max-w-lg bg-white rounded-md">
      <div className="relative">
        <input
          className="block w-full pr-10 py-3 border border-gray-300 rounded-md leading-5 bg-white shadow-sm placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-600 focus:border-blue-600 sm:text-sm"
          placeholder={placeholder}
          type="search"
          value={input}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
        <button
          disabled={!input}
          type="button"
          className={`absolute inset-y-0 right-0 pr-2.5 items-center rounded-r-md ${input ? 'text-blue-600' : 'text-gray-400'}`}
          onClick={() => onSearch(input)}
        >
          <SearchIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}
