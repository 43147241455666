import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import AuthService from '../services/AuthService';

function Verify() {
  const [tip, setTip] = useState('Verifying e-mail...');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const { token } = queryString.parse(location.search);
    AuthService.verifyEmail(token).then((response) => {
      if (response.status === 200) {
        navigate('/login?verified=true');
      }
    }, (error) => {
      const resMessage = (error.response
              && error.response.data
              && error.response.data.message)
          || error.message
          || error.toString();
      setTip(resMessage);
      navigate('/login?verified=false');
    });
  }, [location, navigate]);

  return (
    <div style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',
    }}
    >
      {tip}
    </div>
  );
}

export default Verify;
