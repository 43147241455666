import HttpService from './HttpService';

const API_PATH = '/keywords';

const getAllKeywords = ({ pageNumber, pageSize }) => HttpService
  .get(`${API_PATH}/getAllKeywords`, { pageNumber, pageSize })
  .then(({ keywords, totalCount }) => Promise.resolve({ rows: keywords, totalCount }));

const getKeywordById = (id) => HttpService
  .get(`${API_PATH}/getKeywordById`, { id });

const findKeywordByName = (name) => HttpService
  .get(`${API_PATH}/findKeywordByName`, { name });

const deleteKeyword = (id) => HttpService
  .post(`${API_PATH}/deleteKeyword`, { id });

const createKeyword = ({ name }) => HttpService
  .post(`${API_PATH}/createKeyword`, { name });

const getKeywordConnections = (id) => HttpService
  .get(`${API_PATH}/getKeywordConnections`, { id });

const deleteKeywordConnection = ({ id, id1 }) => HttpService
  .post(`${API_PATH}/deleteKeyword`, { id, id1 });

const editKeywordConnections = ({ id, removedConnections, newConnections }) => HttpService
  .post(`${API_PATH}/editKeywordConnections`, { id, removedConnections, newConnections });

export default {
  getAllKeywords,
  getKeywordById,
  findKeywordByName,
  deleteKeyword,
  createKeyword,
  getKeywordConnections,
  deleteKeywordConnection,
  editKeywordConnections,
};
