import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import LoginForm from '../components/forms/LoginForm';
import Modal from '../components/Modal';
import ForgotPasswordForm from '../components/forms/ForgotPasswordForm';
import AuthService from '../services/AuthService';

function Login() {
  const location = useLocation();
  const [forgotPwd, setForgotPwd] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [requestError, setRequestError] = useState('');
  const [verified, setVerified] = useState('');
  const [modal, setModal] = useState({ open: false });

  const params = queryString.parse(location.search);
  if (params.verified) {
    if (verified !== params.verified) {
      setVerified(params.verified);
    }
  } else if (verified !== '') {
    setVerified('');
  }

  useEffect(() => {
    if (forgotPwd) {
      setModal({
        open: true,
        title: 'Forgot Password',
        content: 'Enter your e-mail address and we will send you a link to reset your password.',
        button: '',
        type: 'email',
      });
    }
  }, [forgotPwd]);

  const handleLogin = ({ email, password }) => {
    AuthService.login(email, password).then(
      () => {
        window.location.href = '/';
      }, (error) => {
        const resMessage = (error.response
          && error.response.data
          && error.response.data.message)
          || error.message
          || error.toString();
        setLoginError(resMessage);
      },
    );
  };

  const handlePasswordResetRequest = ({ email }) => {
    AuthService.passwordResetRequest({ email }).then(
      () => {
        setForgotPwd(false);
        setModal({
          open: true,
          title: 'Request to Reset Password',
          content: 'We have sent you a link to reset your password. Please check your email.',
          button: 'Confirm',
          type: 'success',
        });
      }, (error) => {
        const resMessage = (error.response
          && error.response.data
          && error.response.data.message)
          || error.message
          || error.toString();
        setRequestError(resMessage);
      },
    );
  };

  const handleClose = () => {
    setModal({ open: false });
    if (forgotPwd) setForgotPwd(false);
    if (requestError) setRequestError('');
  };

  return (
    <div>
      <LoginForm
        verified={verified}
        onLogin={handleLogin}
        onForgotPassword={() => { setForgotPwd(true); }}
        message={loginError}
      />
      <Modal
        open={modal.open}
        onClose={handleClose}
        title={modal.title}
        content={modal.content}
        button={modal.button}
        type={modal.type}
      >
        {forgotPwd && (
          <ForgotPasswordForm
            onPasswordResetRequest={handlePasswordResetRequest}
            message={requestError}
          />
        )}
      </Modal>
    </div>
  );
}

export default Login;
