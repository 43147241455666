import { useState } from 'react';

const useOptionMenu = ({ initialOptionIndex, handleActiveOptionChange }) => {
  const [options, setOptions] = useState([]);
  const [activeOptionIndex, setActiveOptionIndex] = useState(initialOptionIndex);
  const [showMenu, setShowMenu] = useState(false);

  const handleArrowKeyDown = (key) => {
    let newActiveOptionIndex;
    if (key === 'ArrowDown') {
      newActiveOptionIndex = activeOptionIndex < options.length - 1
        ? (activeOptionIndex + 1)
        : initialOptionIndex;
    } else if (key === 'ArrowUp') {
      newActiveOptionIndex = activeOptionIndex > initialOptionIndex
        ? (activeOptionIndex - 1)
        : (options.length - 1);
    }
    if (typeof newActiveOptionIndex === 'number') {
      setActiveOptionIndex(newActiveOptionIndex);
      handleActiveOptionChange(newActiveOptionIndex);
    }
  };

  const openMenu = () => {
    setShowMenu(true);
    setActiveOptionIndex(initialOptionIndex);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  const removeOptionByIndex = (index) => {
    setOptions([...options.slice(0, index), ...options.slice(index + 1)]);
  };

  return ({
    options,
    setOptions,
    activeOptionIndex,
    setActiveOptionIndex,
    removeOptionByIndex,
    handleArrowKeyDown,
    showMenu,
    openMenu,
    closeMenu,
  });
};

export default useOptionMenu;
