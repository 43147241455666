import HttpService from './HttpService';

const API_PATH = '/users';

const getAllUsers = ({ pageNumber, pageSize }) => HttpService
  .get(`${API_PATH}/getAllUsers`, { pageNumber, pageSize })
  .then(({ users, totalCount }) => Promise.resolve({ rows: users, totalCount }));

const findUsersByInput = (input) => HttpService
  .get(`${API_PATH}/findUsersByInput`, { input });

const deleteUser = (id) => HttpService
  .post(`${API_PATH}/delete`, { id });

const adminVerify = (id) => HttpService
  .post(`${API_PATH}/adminVerify`, { id });

const toggleAdmin = (id) => HttpService
  .post(`${API_PATH}/toggleAdmin`, { id });

export default {
  getAllUsers,
  findUsersByInput,
  deleteUser,
  adminVerify,
  toggleAdmin,
};
