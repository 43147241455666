import { useState } from 'react';

const useArray = (initialArray) => {
  const [items, setItems] = useState(initialArray);

  const push = (item) => setItems([...items, item]);

  const pop = () => setItems(items.slice(0, -1));

  const removeItemByIndex = (index) => {
    setItems([...items.slice(0, index), ...items.slice(index + 1)]);
  };

  const removeAll = () => setItems([]);

  return [
    items,
    {
      setItems,
      push,
      pop,
      removeItemByIndex,
      removeAll,
    },
  ];
};

export default useArray;
