import React, { useState } from 'react';
import ChangePasswordForm from '../components/forms/ChangePasswordForm';
import EditProfileForm from '../components/forms/EditProfileForm';
import AuthService from '../services/AuthService';
import Modal from '../components/Modal';

function UserProfile() {
  const [editMessage, setEditMessage] = useState('');
  const [pwChangeMessage, setPwChangeMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [openn, setOpenn] = useState(false);
  const getMe = AuthService.getCurrentUser();
  const handleEdit = ({
    token, email, name, password,
  }) => {
    AuthService.edit(token, email, name, password).then(
      (response) => {
        setEditMessage('');
        if (response.status === 201) {
          setOpen(true);
        }
        localStorage.setItem('user', JSON.stringify({ ...response.data, token: getMe.token }));
      },
      (error) => {
        const resMessage = (error.response
            && error.response.data
            && error.response.data.message)
            || error.message
            || error.toString();
        setEditMessage(resMessage);
      },
    );
  };
  const handlePasswordChange = ({
    token, oldPassword, newPassword,
  }) => {
    AuthService.changePassword(token, oldPassword, newPassword).then(
      (response) => {
        setEditMessage('');
        if (response.status === 200) {
          setOpenn(true);
        }
      },
      (error) => {
        const resMessage = (error.response
              && error.response.data
              && error.response.data.message)
              || error.message
              || error.toString();
        setPwChangeMessage(resMessage);
      },
    );
  };
  return (
    <div>
      <main className="pt-10">
        <div className="flex flex-row flex-wrap justify-center divide-x">
          <div className="px-10">
            <Modal open={open} onClose={() => setOpen(false)} title="Change successful!" content="You have successfully changed your user data." button="Continue" type="success" />
            <EditProfileForm
              user={getMe}
              onEdit={handleEdit}
              message={editMessage}
            />
          </div>
          <div className="px-10">
            <Modal open={openn} onClose={() => setOpenn(false)} title="Change successful!" content="You have successfully changed your password." button="Continue" type="success" />
            <ChangePasswordForm
              user={getMe}
              onChange={handlePasswordChange}
              message={pwChangeMessage}
            />
          </div>
        </div>
      </main>
    </div>
  );
}

export default UserProfile;
