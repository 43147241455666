import React, { useEffect } from 'react';
import { CSS2DObject, CSS2DRenderer } from 'three-full';
import ForceGraph3D from 'react-force-graph-3d';
import { forceManyBody } from 'd3-force-3d';

const { useRef } = React;

const force = forceManyBody();
force.strength(-175);

function ResultGraph3D({
  onNodeClick,
  onNodeRightClick,
  otherClick,
  graphData,
  containerWidth,
  containerHeight,
  isMultiColored,
  resetCamera,
  setResetCamera,
}) {
  const fgRef = useRef();
  useEffect(() => {
    if (fgRef.current) {
      fgRef.current.d3Force('link').id((i) => i.id).strength((d) => d.value / 8);
      fgRef.current.d3Force('charge', force);
    }
  }, [graphData]);
  useEffect(() => {
    if (resetCamera && fgRef.current) {
      fgRef.current.zoomToFit(400);
      setResetCamera(false);
    }
  }, [resetCamera, setResetCamera]);
  return (
    <ForceGraph3D
      ref={fgRef}
      extraRenderers={[new CSS2DRenderer()]}
      graphData={graphData}
      nodeResolution={20}
      nodeThreeObject={(node) => {
        const nodeEl = document.createElement('div');
        nodeEl.textContent = node.id;
        nodeEl.style.color = 'black';
        nodeEl.style.padding = '5px';
        if (isMultiColored && node.isSearchTerm) {
          nodeEl.style.paddingLeft = '9px';
          nodeEl.style.paddingRight = '9px';
          nodeEl.style.fontSize = '15px';
          nodeEl.style.border = 'hidden';
          nodeEl.style.borderRadius = '15px';
          nodeEl.style.backgroundColor = 'rgba(104, 127, 217, 0.9)';
          nodeEl.style.color = 'white';
        }
        nodeEl.className = 'node-label';
        return new CSS2DObject(nodeEl);
      }}
      nodeThreeObjectExtend
      width={containerWidth}
      height={containerHeight}
      nodeColor={(node) => node.color}
      nodeVal={(node) => {
        const links = node.numLinks;
        switch (true) {
          case (links <= 3):
            return 0.7;
          case (links > 3 && links <= 5):
            return 1.6;
          case (links > 5 && links <= 10):
            return 2.5;
          default:
            return 4;
        }
      }}
      linkOpacity={0.8}
      linkWidth={0.4}
      linkColor={() => '#949494'}
      backgroundColor="#f8f8f8"
      onNodeClick={(node) => onNodeClick(node)}
      onNodeRightClick={(node, event) => {
        onNodeRightClick({
          x: event.pageX,
          y: event.pageY,
          node,
        });
      }}
      onBackgroundClick={() => {
        otherClick();
      }}
      onLinkClick={() => {
        otherClick();
      }}
      onZoom={() => {
        otherClick();
      }}
      onNodeDrag={() => {
        otherClick();
      }}
    />
  );
}

export default ResultGraph3D;
