import React from 'react';
import classNames from 'classnames';

function ItemBadge(props) {
  const { text, type, onDelete } = props;
  return (
    <span className={classNames('inline-flex max-w-full rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium',
      type === 'valid' ? 'bg-blue-100 text-blue-600' : 'bg-gray-200 text-gray-500')}
    >
      <span className="truncate">
        {text}
      </span>
      <button
        type="button"
        className={classNames('flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center',
          type === 'valid' ? 'text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:bg-blue-500' : 'text-gray-500 hover:bg-gray-300 hover:text-gray-600 focus:bg-gray-500', 'focus:outline-none  focus:text-white')}
        onClick={onDelete}
      >
        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </span>
  );
}

export default ItemBadge;
