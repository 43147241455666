import React, { useRef } from 'react';
import { MinusCircleIcon } from '@heroicons/react/outline';
import AutoCompleteOptions from '../searchbar/AutoCompleteOptions';
import { useAutoComplete } from '../../hooks';

export default function KeywordConnection({
  index, connection, formik, onDelete,
}) {
  const {
    value,
    options,
    activeOptionIndex,
    setValue,
    handleInputChange,
    handleArrowKeyDown,
    showMenu,
    closeMenu,
  } = useAutoComplete({ initialValue: connection.node2, initialOptionIndex: 0 });

  const { errors } = formik;
  const inputRef = useRef(null);

  const handleKeyDown = (e) => {
    const { key } = e;
    switch (key) {
      case 'ArrowDown':
      case 'ArrowUp':
        e.preventDefault();
        handleArrowKeyDown(key);
        break;
      case 'Enter':
        e.preventDefault();
        if (options.length) setValue(options[activeOptionIndex]);
        setTimeout(() => inputRef.current.blur(), 0);
        break;
      default:
        break;
    }
  };

  const handleOptionSelect = (option) => {
    setValue(option);
  };

  const handleEnterKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <>
      <div className="col-start-1 col-span-1 place-self-center">
        <p>{index + 1}</p>
      </div>
      <div className="col-span-5">
        <label htmlFor={`connections.${index}.node2`} className="block text-sm font-medium text-gray-700">
          Keyword name
          <div className="relative mt-1">
            <input
              type="text"
              id={`connections.${index}.node2`}
              name={`connections.${index}.node2`}
              ref={inputRef}
              value={value}
              required={connection.score}
              autoComplete="off"
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onBlur={() => {
                closeMenu();
                formik.setFieldValue(`connections.${index}.node2`, value);
              }}
            />
            {showMenu
              && (
                <AutoCompleteOptions
                  activeIndex={activeOptionIndex}
                  options={options}
                  onSelect={handleOptionSelect}
                />
              )}
          </div>
        </label>
        {/* {errors.connections
          && errors.connections[index]
          && errors.connections[index].node2
          && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {errors.connections[index].node2}
            </p>
          )} */}
      </div>
      <div className="col-span-4">
        <label htmlFor={`connections.${index}.score`} className="block text-sm font-medium text-gray-700">
          Score
          <div className="mt-1">
            <input
              type="text"
              id={`connections.${index}.score`}
              name={`connections.${index}.score`}
              value={connection.score}
              required={value}
              autoComplete="off"
              onChange={formik.handleChange}
              onKeyDown={handleEnterKeyDown}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </label>
        {errors.connections
          && errors.connections[index]
          && errors.connections[index].score
          && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {errors.connections[index].score}
            </p>
          )}
      </div>
      <div className="col-span-1 place-self-center">
        <button type="button" onClick={onDelete} className="p-1 text-red-400 hover:text-red-600">
          <MinusCircleIcon className="h-6 w-6 " aria-hidden="true" />
        </button>
      </div>
    </>
  );
}
